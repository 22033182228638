import { Tooltip, TooltipProps } from 'antd';
import useResponsive from 'hooks/useResponsive';
import React, { FC, HTMLAttributes, useRef, useState } from 'react';

type Props = {
  name?: string;
  children: React.ReactNode;
  delayTime?: number;
  TooltipComponentProps?: TooltipProps;
  TypographyComponentProps?: HTMLAttributes<HTMLDivElement>;
};

/**
 * This component required width or max-width of parent element to work.
 */
const SmartTooltip: FC<Props> = ({
  children,
  name,
  delayTime,
  TypographyComponentProps = {},
  TooltipComponentProps = {}
}) => {
  const textComponentRef = useRef<HTMLDivElement | null>(null);
  const [tooltipTitle, setTooltipTitle] = useState<string>('');

  const { isMobile } = useResponsive();

  const handleOpenTooltip = () => {
    if (textComponentRef.current) {
      if (textComponentRef.current.scrollWidth > textComponentRef.current.offsetWidth) {
        setTooltipTitle(textComponentRef.current.innerText);
      } else {
        setTooltipTitle('');
      }
    }
  };

  return (
    <Tooltip
      trigger={isMobile ? 'hover' : 'click'}
      title={tooltipTitle}
      mouseEnterDelay={delayTime ?? 0}
      placement="top"
      destroyTooltipOnHide
      {...TooltipComponentProps}
    >
      <div
        ref={textComponentRef}
        data-name={name}
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }}
        {...TypographyComponentProps}
        onMouseEnter={handleOpenTooltip}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export { SmartTooltip };
