import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton, Tabs, Tooltip } from 'antd';

import type { TAccount, TCameraGroupType } from 'models';
import type { TCameraGroupGeneralFieldType } from 'presentation/pages/CameraGroupsPage/cameraGroupPage.types';

import { eossDocLinks, MAXIMUM_64_CHARACTERS, MAXIMUM_8_CHARACTERS } from 'constant';

import { useAppUtil } from 'context/UtilContext';

import { FormAction, Section } from 'presentation/components';
import { General } from '../../CameraGroupsPage/components';
import TableEyeviewUserAssignment from '../components/TableEyeviewUserAssignment';

import { useCameraGroupInformationPageController } from './CameraGroupInformationPageController';

import styles from './cameraGroupInformationPageView.module.scss';
import { DeviceBinding } from '../components/DeviceBinding';
import { ServiceNote } from '../components/ServiceNote';
import useResponsive from 'hooks/useResponsive';

function TotalDeviceComponent({ totalDevice }: { totalDevice: number }) {
  const { isMobile } = useResponsive();
  const { t } = useTranslation();
  if (totalDevice === 0) return <></>;
  return (
    <div className="d-flex justify-content-end fs-14 fw-normal me-4 mb-4">
      <Tooltip
        trigger={isMobile ? ['click'] : ['hover']}
        destroyTooltipOnHide
        placement="topLeft"
        title={t('cameraGroupPage.tabs.deviceBinding.extraFooter.tooltip')}
      >
        {t('cameraGroupPage.tabs.deviceBinding.extraFooter.label', { device: totalDevice })}
        {totalDevice > 1 ? 's' : ''}
      </Tooltip>
    </div>
  );
}

function CameraGroupInformationPageView() {
  const {
    isAllowedViewServiceNote,
    isInEdit,
    infiniteScrollRef,
    warningMessage,
    isAllPublicIPMatches,
    isShowRefreshButton,
    formEditCameraGroup,
    orderSelectValueDeviceTab,
    orderSelectValueCameraTab,
    cameraGroupSelectedRow,
    astroDeviceSelectedRow,
    upsMonitorSelectedRow,
    eyeviewUserSelectedRow,
    cameraBindingSwitchValue,
    listCameraBinding,
    deviceBindingSummary,
    astroDeviceList,
    upsMonitorList,
    userAssignmentList,
    cameraGroupTypes,
    serviceNoteList,
    focusingID,
    topLoading,
    selectSortFilter,
    searchServiceNoteValue,
    hasMore,
    total,
    onLoadMore,
    onCancel,
    onCameraBindingListChange,
    onCameraBindingRefresh,
    onAstroDeviceRefresh,
    onUpsMonitorRefresh,
    onCameraGroupSelectedRowChange,
    onAstroDeviceSelectedRowChange,
    onUpsMonitorSelectedRowChange,
    onEyeviewUserSelectedRowChange,
    onFormSubmit,
    onCameraBindingSwitchChange,
    onOrderTableCameraBindingChange,
    onInEditChange,
    onFocusingIDChange,
    onAddNote,
    onEditNote,
    onDeleteNote,
    onSelectSortFilterChange,
    onClickRefreshServiceNote,
    onServiceNoteSearchChange
  } = useCameraGroupInformationPageController();

  const { t } = useTranslation();
  const { openModal } = useAppUtil();

  const generalRef = useRef<HTMLDivElement | null>(null);

  const [maxHeight, setMaxHeight] = useState<number>();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const handleConfirmSubmit = (values: TCameraGroupGeneralFieldType) => {
    const name = values.name.trim();
    if (name.length < MAXIMUM_8_CHARACTERS || name.length > MAXIMUM_64_CHARACTERS) {
      formEditCameraGroup.setFields([
        {
          name: 'name',
          errors: [t('forms.cameraGroupName.pattern')]
        }
      ]);
    } else {
      setSubmitting(true);
      openModal({
        title: t('components.confirmationTitle'),
        content: t('components.confirmationMessage', {
          action: t('actions.update').toLowerCase(),
          entity: t('cameraGroupPage.entity').toLowerCase()
        }),
        okText: t('components.ok'),
        cancelText: t('components.cancel'),
        onOk: async () => {
          await onFormSubmit(values);
          setSubmitting(false);
        },
        onCancel: () => setSubmitting(false),
        wrapClassName: styles.confirmModal
      });
    }
  };

  const totalDevice = useMemo((): number => {
    return (
      astroDeviceList.data.length + upsMonitorList.data.length + deviceBindingSummary.totalDevices
    );
  }, [astroDeviceList, deviceBindingSummary, upsMonitorList]);

  const tabs = [
    {
      key: '1',
      label: t('cameraGroupPage.tabs.deviceBinding.title'),
      children: (
        <>
          <DeviceBinding
            isInEdit={isInEdit}
            astroDeviceList={astroDeviceList}
            astroDeviceSelectedRow={astroDeviceSelectedRow}
            cameraBindingSwitchValue={cameraBindingSwitchValue}
            cameraGroupSelectedRow={cameraGroupSelectedRow}
            deviceBindingSummary={deviceBindingSummary}
            isShowRefreshButton={isShowRefreshButton}
            listCameraBinding={listCameraBinding}
            orderSelectValueCameraTab={orderSelectValueCameraTab}
            orderSelectValueDeviceTab={orderSelectValueDeviceTab}
            upsMonitorList={upsMonitorList}
            upsMonitorSelectedRow={upsMonitorSelectedRow}
            warningMessage={warningMessage}
            onAstroDeviceRefresh={onAstroDeviceRefresh}
            onAstroDeviceSelectedRowChange={onAstroDeviceSelectedRowChange}
            onCameraBindingListChange={onCameraBindingListChange}
            onCameraBindingRefresh={onCameraBindingRefresh}
            onCameraBindingSwitchChange={onCameraBindingSwitchChange}
            onCameraGroupSelectedRowChange={onCameraGroupSelectedRowChange}
            onInEditChange={onInEditChange}
            onOrderTableCameraBindingChange={onOrderTableCameraBindingChange}
            onUpsMonitorRefresh={onUpsMonitorRefresh}
            onUpsMonitorSelectedRowChange={onUpsMonitorSelectedRowChange}
          />
          <TotalDeviceComponent totalDevice={totalDevice} />
        </>
      )
    },
    {
      key: '2',
      label: t('cameraGroupPage.tabs.serviceNote.title'),
      children: (
        <ServiceNote
          infiniteScrollRef={infiniteScrollRef}
          hasMore={hasMore}
          focusingID={focusingID}
          total={total}
          topLoading={topLoading}
          searchServiceNoteValue={searchServiceNoteValue}
          selectSortFilter={selectSortFilter}
          dataSource={serviceNoteList}
          skeleton={<Skeleton loading={true} active paragraph={{ rows: 2 }} />}
          onFocusingIDChange={onFocusingIDChange}
          onLoadMore={onLoadMore}
          onAddNote={onAddNote}
          onEditNote={onEditNote}
          onDeleteNote={onDeleteNote}
          onSelectSortFilterChange={onSelectSortFilterChange}
          onClickRefreshServiceNote={onClickRefreshServiceNote}
          onServiceNoteSearchChange={onServiceNoteSearchChange}
        />
      )
    }
  ];

  useEffect(() => {
    if (generalRef.current) {
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.contentRect.height) {
            setMaxHeight(entry.contentRect.height);
          }
        }
      });

      observer.observe(generalRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className="row gx-4">
        <div className="col-12 col-md-8">
          <Section
            bodyRef={generalRef}
            title={t('cameraGroupPage.sections.general.title')}
            docLink={eossDocLinks.deviceGroup.details.generalSection}
            classNameBody="p-4"
            warningMessage={
              isAllPublicIPMatches ? '' : t('cameraGroupPage.sections.general.publicIPMismatch')
            }
          >
            <General<TCameraGroupType, TAccount>
              form={formEditCameraGroup}
              editMode
              cameraGroupTypes={cameraGroupTypes}
              onSubmit={handleConfirmSubmit}
            />
          </Section>
        </div>
        <div className="col-12 col-md-4">
          <Section
            keyPanel="eyeview-user-assignment-device-group-page"
            title={t('cameraGroupPage.sections.eyeviewUser.title')}
            docLink={eossDocLinks.deviceGroup.details.assignEyeviewUserSection}
            allowCollapse
            expandedByDefault
          >
            <TableEyeviewUserAssignment
              maxHeight={maxHeight}
              userAssignmentList={userAssignmentList}
              eyeviewUserSelectedRow={eyeviewUserSelectedRow}
              onEyeviewUserSelectedRowChange={onEyeviewUserSelectedRowChange}
            />
          </Section>
        </div>
      </div>
      <>
        {isAllowedViewServiceNote ? (
          <Tabs type="card" items={tabs} />
        ) : (
          <>
            <DeviceBinding
              isInEdit={isInEdit}
              astroDeviceList={astroDeviceList}
              astroDeviceSelectedRow={astroDeviceSelectedRow}
              cameraBindingSwitchValue={cameraBindingSwitchValue}
              cameraGroupSelectedRow={cameraGroupSelectedRow}
              deviceBindingSummary={deviceBindingSummary}
              isShowRefreshButton={isShowRefreshButton}
              listCameraBinding={listCameraBinding}
              orderSelectValueCameraTab={orderSelectValueCameraTab}
              orderSelectValueDeviceTab={orderSelectValueDeviceTab}
              upsMonitorList={upsMonitorList}
              upsMonitorSelectedRow={upsMonitorSelectedRow}
              warningMessage={warningMessage}
              onAstroDeviceRefresh={onAstroDeviceRefresh}
              onAstroDeviceSelectedRowChange={onAstroDeviceSelectedRowChange}
              onCameraBindingListChange={onCameraBindingListChange}
              onCameraBindingRefresh={onCameraBindingRefresh}
              onCameraBindingSwitchChange={onCameraBindingSwitchChange}
              onCameraGroupSelectedRowChange={onCameraGroupSelectedRowChange}
              onInEditChange={onInEditChange}
              onOrderTableCameraBindingChange={onOrderTableCameraBindingChange}
              onUpsMonitorRefresh={onUpsMonitorRefresh}
              onUpsMonitorSelectedRowChange={onUpsMonitorSelectedRowChange}
            />
            <TotalDeviceComponent totalDevice={totalDevice} />
          </>
        )}
      </>

      <FormAction
        onCancel={onCancel}
        onSubmit={() => formEditCameraGroup.submit()}
        SubmitTypographyProps={{
          disabled: isSubmitting
        }}
      />
    </div>
  );
}

export default CameraGroupInformationPageView;
