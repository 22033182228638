/**
 * Represents a standard error response structure from the API.
 *
 * @property {string} error - A descriptive error message.
 * @property {number} status - The HTTP status code associated with the error.
 * @property {string} [path] - Optional path associated with the error.
 * @property {string} [message] - Optional additional error message.
 * @property {string} [timestamp] - Optional timestamp indicating when the error occurred.
 * @author thai.ngo@zien.vn
 */
interface ErrorResponse {
  error: string;
  status: number;
  path?: string;
  message?: string;
  timestamp?: string;
}

/**
 * Represents an error when username or password is incorrect.
 *
 * @extends ErrorResponse - Inherits the standard error response properties.
 */
type UserNameOrPassError = ErrorResponse & {
  error: 'USERNAME_OR_PASSWORD_INCORRECT';
  status: 401;
};

/**
 * Represents an error when a resource is disabled (HTTP 403). This error is
 * used for the case when the current admin user is disabled.
 * When this error occurs, the admin should be logged out and redirected to the Login page.
 * @extends ErrorResponse - Inherits the standard error response properties.
 */
type DisabledResource403 = ErrorResponse & {
  error: 'DISABLED_RESOURCE';
  status: 403;
};

/**
 * Represents an error when password invalid rule (HTTP 400).
 *
 * @extends ErrorResponse - Inherits the standard error response properties.
 */
type PasswordValidationError = ErrorResponse & {
  error: 'VALIDATION_ERROR';
  status: 400;
};

/**
 * Represents an error when a resource is disabled (HTTP 409).
 *
 * @extends ErrorResponse - Inherits the standard error response properties.
 */
type DisabledResource409 = ErrorResponse & {
  error: 'DISABLED_RESOURCE';
  status: 409;
};

/**
 * Represents an error indicating that the user attempted to access a resource or
 * perform an action that they do not have sufficient permissions for. This differs
 * from a general authentication error (401) by implying the user is authenticated,
 * but lacks the necessary authorization level.
 *
 * @extends ErrorResponse - Inherits the standard error response properties.
 */
type UnAuthorizedAccess = ErrorResponse & {
  error: 'UNAUTHORIZED_ACCESS';
  status: 403;
};

/**
 * Represents an error where the client cannot establish a connection with the server.
 *
 * @extends ErrorResponse - Inherits the standard error response properties.
 */
type InternalServerError = ErrorResponse & {
  error: 'Internal Server Error';
  status: 500;
};

/**
 * Represents an error specifically indicating that a requested resource
 * could not be found on the server.
 *
 * @extends ErrorResponse - Inherits the standard error response properties.
 */
type NotFoundError = ErrorResponse & {
  error: 'NOT_FOUND';
  status: 404;
};

/**
 * Represents an error indicating that an attempt was made to create/update a resource
 * that already exists, resulting in a conflict.
 *
 * @extends ErrorResponse - Inherits the standard error response properties.
 */
type DuplicateResourceError = ErrorResponse & {
  error: 'DUPLICATE_RESOURCE';
  status: 409;
};

/**
 * Represents an error signifying that the user does not have the required permissions to access a resource or perform an action.
 *
 * @extends ErrorResponse - Inherits the standard error response properties.
 */
type ForbiddenError = ErrorResponse & {
  error: 'Forbidden';
  status: 403;
};

/**
 * Represents an error signifying a malformed or invalid request from the client.
 *
 * @extends ErrorResponse - Inherits the standard error response properties.
 */
type BadRequestError = ErrorResponse & {
  error: 'Bad Request';
  status: 400;
};

/**
 * Represents an error where the client cannot establish a connection with the server.
 *
 * @extends ErrorResponse - Inherits the standard error response properties.
 */
type ServerUnReachableError = ErrorResponse & {
  error: 'SERVER_UNREACHABLE';
  status: 400;
};

/**
 * Represents a generic error where the specific cause is unknown or cannot be
 * classified into a more specific error type. This serves as a catch-all for
 * unexpected situations.
 *
 * @extends ErrorResponse - Inherits the standard error response properties.
 */
type UnknownError = ErrorResponse & {
  error: 'UNKNOWN_ERROR';
  status: 400;
};

type FreezingActivityError = ErrorResponse & {
  error: 'FREEZING_ACTIVITY_ERROR';
  status: 504;
};

type InvalidTypeError = ErrorResponse & {
  error: 'INVALID_TYPE';
  status: 400;
};

type CameraBindingError = ErrorResponse & {
  error: 'CAMERA_BINDING_NOT_ALLOWED';
  status: 400;
};

type ResourceNotFoundError = ErrorResponse & {
  error: 'RESOURCE_NOT_FOUND';
  status: 404;
};

type MultipleResourcesFound = ErrorResponse & {
  error: 'MULTIPLE_RESOURCES_FOUND';
  status: 400;
};

/**
 * An error representing an expired token.
 * The error code, which is always 'EXPIRED_TOKEN'.
 * The HTTP status code, which is always 403.
 *
 * @extends ErrorResponse - Inherits the standard error response properties.
 */
type ExpiredToken = ErrorResponse & {
  error: 'EXPIRED_TOKEN';
  status: 403;
};

type ReplaceDeviceItselfError = ErrorResponse & {
  error: 'REPLACE_DEVICE_ITSELF';
  status: 400;
};

/**
 * Represents the possible actions for an API endpoint.
 */
export type APIAction = 'create' | 'update' | 'get' | 'delete' | 'other';

export const freezingActivityError: FreezingActivityError = {
  error: 'FREEZING_ACTIVITY_ERROR',
  status: 504
};

export const unknownError: UnknownError = {
  error: 'UNKNOWN_ERROR',
  status: 400
};

export const expiredTokenError: ExpiredToken = {
  error: 'EXPIRED_TOKEN',
  status: 403
};

export const disabledAuthError: DisabledResource403 = {
  error: 'DISABLED_RESOURCE',
  status: 403
};

export const notFoundError: NotFoundError = {
  error: 'NOT_FOUND',
  status: 404
};

export const resourceNotFound: ResourceNotFoundError = {
  error: 'RESOURCE_NOT_FOUND',
  status: 404
};

export const invalidTypeError: InvalidTypeError = {
  error: 'INVALID_TYPE',
  status: 400
};

export const replaceDeviceItselfError: ReplaceDeviceItselfError = {
  error: 'REPLACE_DEVICE_ITSELF',
  status: 400
};

export const multipleResourcesFound: MultipleResourcesFound = {
  error: 'MULTIPLE_RESOURCES_FOUND',
  status: 400
};

export const unAuthorizedAccessError: UnAuthorizedAccess = {
  error: 'UNAUTHORIZED_ACCESS',
  status: 403
};

export const cameraBindingNotAllowed: CameraBindingError = {
  error: 'CAMERA_BINDING_NOT_ALLOWED',
  status: 400
};

export type ApiErrorResponse =
  | UserNameOrPassError
  | DisabledResource403
  | DisabledResource409
  | UnAuthorizedAccess
  | CameraBindingError
  | InternalServerError
  | NotFoundError
  | DuplicateResourceError
  | ForbiddenError
  | BadRequestError
  | ServerUnReachableError
  | InvalidTypeError
  | UnknownError
  | ExpiredToken
  | PasswordValidationError
  | FreezingActivityError
  | ResourceNotFoundError
  | MultipleResourcesFound
  | ReplaceDeviceItselfError;
