import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEqual, orderBy } from 'lodash';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
  useOutletContext,
  useParams,
  useRouteLoaderData,
  useSearchParams
} from 'react-router-dom';

import type { SegmentedValue } from 'antd/es/segmented';
import type {
  DataList,
  TCameraBinding,
  TCameraGroup,
  TCameraGroupType,
  TEyeviewUserAssign,
  TOtherDevice
} from 'models';
import type { TCameraGroupGeneralFieldType } from 'presentation/pages/CameraGroupsPage/cameraGroupPage.types';

import {
  DEFAULT_PAGE_NUMBER,
  INTERVAL_TIME_REFRESH_DEVICE_GROUP_BINDING,
  SMALL_LIMIT
} from 'constant';
import { useLoaderContext } from 'context/LoaderContext';
import { useAppUtil } from 'context/UtilContext';

import {
  ECameraBindingSwitchOption,
  EDeviceType,
  EHttpStatusCode,
  ERoleKey,
  ESortingDevicePage,
  ESortingServiceNotes,
  ESubFeatureKey,
  ECameraType,
  Routes
} from 'enums';

import { useEffectOnce } from 'hooks/useEffectOnce';
import useInterval from 'hooks/useInterval';

import { ApiErrorResponse } from 'models/ApiError';

import { CameraGroupRepository } from 'repositories';

import { AxiosClient } from 'services/axios';

import {
  checkDeviceStatus,
  handleApiError,
  isJsonString,
  isThermalCameraInThermalOpticalDevice,
  parseENotationNumberToString,
  sortDeviceBinding
} from 'utils/common';
import { useAsyncState } from 'hooks/useAsyncState';
import { TServiceNote } from 'models/ServiceNote';
import useDebounce from 'hooks/useDebounce';
import { useSkipFirstEffect } from 'hooks/useSkipFirstEffect';
import { usePermission } from 'hooks/usePermission';

type TAction = 'sync' | 'bind' | 'assign';

export const useCameraGroupInformationPageController = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formEditCameraGroup] = Form.useForm<TCameraGroupGeneralFieldType>();

  const { openNotification } = useAppUtil();

  const { loader, isActive } = useLoaderContext();

  const cameraGroupRepository = CameraGroupRepository(AxiosClient);

  const { cameraGroupId } = useParams<{ cameraGroupId: string }>();

  const { actionStatus, onChangeCurrentCamera, onResetActionStatus, resetBindForm } =
    useOutletContext<{
      actionStatus: {
        type: TAction | null;
        success: boolean;
      };
      onChangeCurrentCamera: (value: TCameraGroup) => void;
      onResetActionStatus: () => void;
      resetBindForm: () => void;
    }>();

  const currentCameraGroupLoader = useRouteLoaderData(
    Routes.ECameraGroupRoutes.DETAILED
  ) as TCameraGroup;

  const [searchParams, setSearchParams] = useSearchParams();

  const [warningMessage, setWarningMessage] = useState<string>('');

  const [userAssignmentList, setUserAssignmentList] = useAsyncState<TEyeviewUserAssign[]>([]);
  const [listCameraBinding, setListCameraBinding] = useAsyncState<
    Array<TCameraBinding & { key: string }>
  >([]);
  const [cameraGroupTypes, setCameraGroupTypes] = useAsyncState<TCameraGroupType[]>([]);
  const [astroDeviceList, setAstroDeviceList] = useAsyncState<TOtherDevice[]>([]);
  const [upsMonitorList, setUpsMonitorList] = useAsyncState<TOtherDevice[]>([]);

  const infiniteScrollRef = useRef<{
    onScrollTop: () => void;
  }>();

  const pageNumber = useRef<number>(DEFAULT_PAGE_NUMBER);

  const hasMore = useRef<boolean>(true);

  const [serviceNoteList, setServiceNoteList, startLoading, stopLoading] = useAsyncState<
    TServiceNote[]
  >([]);

  const [topLoading, setTopLoading] = useState<boolean>(false);

  const [focusingID, setFocusingID] = useState<number>();

  const [selectSortFilter, setSelectSortFilter] = useState<number>(
    ESortingServiceNotes.ORDER_BY_DATE_DESC
  );

  const [total, setTotal] = useState<number>(0);

  const [searchServiceNoteValue, setSearchServiceNoteValue] = useState<string>('');

  const debounceSearchServiceNoteValue = useDebounce(searchServiceNoteValue);

  const { allowed: isAllowedViewServiceNote } = usePermission(
    ERoleKey.VIEW,
    ESubFeatureKey.SERVICE_NOTE
  );

  const [orderSelectValueDeviceTab, setOrderSelectValueDeviceTab] =
    useState<ESortingDevicePage | null>(ESortingDevicePage.NO_SORT);
  const [orderSelectValueCameraTab, setOrderSelectValueCameraTab] = useState<ESortingDevicePage>(
    ESortingDevicePage.NO_SORT
  );

  const [cameraGroupSelectedRow, setCameraGroupSelectedRow] = useState<React.Key[]>(() =>
    currentCameraGroupLoader.cameras
      .filter(
        ({ deviceTypeName = '', cameraType = '' }: TCameraBinding) =>
          !(
            deviceTypeName.toUpperCase() === EDeviceType.THERMAL_OPTICAL_CAMERA &&
            cameraType.toLowerCase() === ECameraType.THERMAL
          )
      )
      .map(({ id }) => id.toString())
  );
  const [eyeviewUserSelectedRow, setEyeviewUserSelectedRow] = useState<React.Key[]>([]);
  const [astroDeviceSelectedRow, setAstroDeviceSelectedRow] = useState<React.Key[]>([]);
  const [upsMonitorSelectedRow, setUpsMonitorSelectedRow] = useState<React.Key[]>([]);

  const [cameraBindingSwitchValue, setCameraBindingSwitchValue] =
    useState<ECameraBindingSwitchOption>(ECameraBindingSwitchOption.Device);

  const [currentCameraGroup, setCurrentCameraGroup] =
    useState<TCameraGroup>(currentCameraGroupLoader);

  const [isInEdit, setIsInEdit] = useState<boolean>(false);

  const isAllPublicIPMatches: boolean = useMemo(() => {
    const uniquePublicIps = new Set<string>();

    [...currentCameraGroup.cameras, ...currentCameraGroup.devices].forEach(
      ({ lastContactAt, publicIp }: TCameraBinding | TOtherDevice) => {
        if (checkDeviceStatus(lastContactAt) && publicIp) {
          uniquePublicIps.add(publicIp);
        }
      }
    );

    return uniquePublicIps.size <= 1;
  }, [currentCameraGroup]);

  // Do not show the refresh button unless there cameras has been a change to the order.
  const isShowRefreshButton: boolean = useMemo(() => {
    const areListsInOrder = (list1: string[], list2: string[]): boolean =>
      list1.length === list2.length && list1.every((item, index) => item === list2[index]);

    const getFilteredSerialNumbers = (cameras: TCameraBinding[]): string[] =>
      cameras
        .filter(
          ({ cameraType, deviceTypeName }) =>
            !isThermalCameraInThermalOpticalDevice({ cameraType, deviceTypeName })
        )
        .map(({ serialNumber }) => serialNumber);

    const sortedIds = (ids: (string | React.Key)[]): string[] =>
      [...ids].map((id) => id.toString()).sort((a, b) => Number(b) - Number(a));

    const currentSerials = getFilteredSerialNumbers(currentCameraGroup.cameras);
    const bindingSerials = getFilteredSerialNumbers(listCameraBinding.data);

    const isNotSameOrder = !areListsInOrder(currentSerials, bindingSerials);

    const defaultCheckedIds = sortedIds(
      Array.from(
        new Set(
          currentCameraGroup.cameras
            .filter(
              ({ cameraType, deviceTypeName }: TCameraBinding) =>
                !isThermalCameraInThermalOpticalDevice({
                  cameraType,
                  deviceTypeName
                })
            )
            .map(({ id }) => id.toString())
        )
      )
    );
    const selectedSortedIds = sortedIds(cameraGroupSelectedRow);
    const isNotSameChecked = !areListsInOrder(defaultCheckedIds, selectedSortedIds);

    return isNotSameOrder || isNotSameChecked;
  }, [currentCameraGroup, listCameraBinding, cameraGroupSelectedRow]);

  /**
   * FUNCTION/UTIL REGION
   */

  const mapToCameraBindingListWithKey = (
    cameras: TCameraBinding[]
  ): (TCameraBinding & {
    key: string;
  })[] =>
    cameras.map((camera: TCameraBinding) => ({
      ...camera,
      key: camera.id.toString()
    }));

  const handleRefetchCameraGroupBinding = async () => {
    const cameraGroupIdNumber = Number(cameraGroupId);
    if (!cameraGroupId || Number.isNaN(cameraGroupIdNumber)) return;
    try {
      setListCameraBinding((prev) => ({
        ...prev,
        loading: true
      }));
      const { data, code } = await cameraGroupRepository.getCameraGroupById(cameraGroupIdNumber);
      if (code === EHttpStatusCode.OK) {
        setCurrentCameraGroup((prev) => ({
          ...prev,
          cameras: data.cameras
        }));
        onChangeCurrentCamera(data);
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('cameraGroupPage.entity'),
        identifier: cameraGroupId.toString(),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('cameraGroupPage.sections.deviceBind.title')}`,
        description: message
      });
    } finally {
      setListCameraBinding((prev) => ({
        ...prev,
        loading: false
      }));
    }
  };

  const handleRefetchEyeviewUserAssignment = async () => {
    const cameraGroupIdNumber = Number(cameraGroupId);
    if (!cameraGroupId || Number.isNaN(cameraGroupIdNumber)) return;
    try {
      setUserAssignmentList((prev) => ({
        ...prev,
        loading: true
      }));
      const { data, code } = await cameraGroupRepository.getCameraGroupById(cameraGroupIdNumber);
      if (code === EHttpStatusCode.OK) {
        setCurrentCameraGroup((prev) => ({
          ...prev,
          users: data.users
        }));
        onChangeCurrentCamera(data);
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('cameraGroupPage.entity'),
        identifier: cameraGroupId.toString(),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('cameraGroupPage.sections.eyeviewUser.title')}`,
        description: message
      });
    } finally {
      setUserAssignmentList((prev) => ({
        ...prev,
        loading: false
      }));
    }
  };

  const handleFetchCameraGroupType = async () => {
    if (cameraGroupTypes.loading) return;
    try {
      const { data, code } = await cameraGroupRepository.getListCameraGroupType();
      if (code === EHttpStatusCode.OK) {
        setCameraGroupTypes((prev) => ({
          ...prev,
          data
        }));
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('cameraGroupType.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('cameraGroupType.entity')}`,
        description: message
      });
    } finally {
      setCameraGroupTypes((prev) => ({
        ...prev,
        loading: false
      }));
    }
  };

  const handleCameraBindingRefresh = async () => {
    const cameraGroupIdNumber = Number(cameraGroupId);
    if (listCameraBinding.loading || !cameraGroupId || Number.isNaN(cameraGroupIdNumber)) return;
    try {
      setListCameraBinding((prev) => ({
        ...prev,
        loading: true
      }));
      const {
        data: { cameras },
        code
      } = await cameraGroupRepository.getCameraGroupById(cameraGroupIdNumber);
      if (code === EHttpStatusCode.OK) {
        setOrderSelectValueDeviceTab(ESortingDevicePage.NO_SORT);
        setOrderSelectValueCameraTab(ESortingDevicePage.NO_SORT);
        setIsInEdit(false);
        setCurrentCameraGroup((prev) => ({
          ...prev,
          cameras
        }));
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('cameraGroupPage.entity'),
        identifier: cameraGroupId.toString(),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('cameraGroupPage.sections.deviceBind.title')}`,
        description: message
      });
    } finally {
      setListCameraBinding((prev) => ({
        ...prev,
        loading: false
      }));
    }
  };

  const handleUpsMonitorRefresh = async () => {
    const cameraGroupIdNumber = Number(cameraGroupId);
    if (upsMonitorList.loading || !cameraGroupId || Number.isNaN(cameraGroupIdNumber)) return;
    try {
      setUpsMonitorList((prev) => ({
        ...prev,
        loading: true
      }));
      const cameraGroupIdNumber = Number(cameraGroupId);
      const { data, code } = await cameraGroupRepository.getCameraGroupById(cameraGroupIdNumber);

      if (code === EHttpStatusCode.OK) {
        setCurrentCameraGroup((prev) => ({
          ...prev,
          devices: data.devices
        }));
        onChangeCurrentCamera(data);
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('cameraGroupPage.entity'),
        identifier: cameraGroupId.toString(),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('cameraGroupPage.sections.ups.title')}`,
        description: message
      });
    } finally {
      setUpsMonitorList((prev) => ({
        ...prev,
        loading: false
      }));
    }
  };

  const handleAstroDeviceRefresh = async () => {
    const cameraGroupIdNumber = Number(cameraGroupId);
    if (astroDeviceList.loading || !cameraGroupId || Number.isNaN(cameraGroupIdNumber)) return;
    try {
      setAstroDeviceList((prev) => ({
        ...prev,
        loading: true
      }));
      const { data, code } = await cameraGroupRepository.getCameraGroupById(cameraGroupIdNumber);
      if (code === EHttpStatusCode.OK) {
        setCurrentCameraGroup((prev) => ({
          ...prev,
          devices: data.devices
        }));
        onChangeCurrentCamera(data);
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('cameraGroupPage.entity'),
        identifier: cameraGroupId.toString(),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('cameraGroupPage.sections.astroDevice.title')}`,
        description: message
      });
    } finally {
      setAstroDeviceList((prev) => ({
        ...prev,
        loading: false
      }));
    }
  };

  const handleAstroDeviceSelectedRowChange = useCallback(
    (value: React.Key[]) => setAstroDeviceSelectedRow(value),
    []
  );

  const handleUpsMonitorSelectedRowChange = useCallback(
    (value: React.Key[]) => setUpsMonitorSelectedRow(value),
    []
  );

  const handleUpdateCameraGroup = async ({
    street = '',
    city = '',
    state = '',
    zip = '',
    ...values
  }: TCameraGroupGeneralFieldType): Promise<EHttpStatusCode> => {
    if (!cameraGroupId) return EHttpStatusCode.NOT_FOUND;
    try {
      const addressData =
        currentCameraGroup?.address && isJsonString(currentCameraGroup?.address)
          ? JSON.parse(currentCameraGroup?.address)
          : {
              street: currentCameraGroup?.address,
              city: '',
              state: '',
              zip: ''
            };
      const oldData = {
        cameraGroupId: Number(cameraGroupId),
        name: currentCameraGroup.name,
        cameraGroupTypeId: currentCameraGroup.type.id,
        description: currentCameraGroup.comment,
        latitude: currentCameraGroup.lat,
        longitude: currentCameraGroup.lon,
        address: addressData,
        locationNumber: currentCameraGroup.locationNumber,
        siteType: currentCameraGroup.siteType
      };
      const newData = {
        cameraGroupId: Number(cameraGroupId),
        name: values.name.trim(),
        cameraGroupTypeId: values.cameraGroupTypeId,
        description: values.comment?.trim() || '',
        address: JSON.stringify({
          street: street.trim(),
          city: city.trim(),
          state: state.trim(),
          zip: zip.trim()
        }),
        latitude: values.latitude ? Number(values.latitude) : undefined,
        longitude: values.longitude ? Number(values.longitude) : undefined,
        locationNumber: values.locationNumber?.trim() || null,
        siteType: values.siteType?.trim() || null
      };

      if (isEqual(oldData, newData)) {
        return EHttpStatusCode.NOT_MODIFIED;
      }
      return await cameraGroupRepository.updateCameraById(newData);
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'update',
        entity: t('cameraGroupPage.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.update')} ${t('cameraGroupPage.entity')}`,
        description: message
      });
      return EHttpStatusCode.BAD_REQUEST;
    }
  };

  const handleUnbindCameraFromCameraGroup = async (): Promise<EHttpStatusCode> => {
    if (!cameraGroupId) return EHttpStatusCode.NOT_FOUND;
    const nonThermalSerials = (cameras: TCameraBinding[]): string[] =>
      cameras
        .filter(
          ({ cameraType, deviceTypeName }: TCameraBinding) =>
            !isThermalCameraInThermalOpticalDevice({ cameraType, deviceTypeName })
        )
        .map(({ serialNumber }: TCameraBinding) => serialNumber.toString());
    try {
      const oldData = nonThermalSerials(currentCameraGroup.cameras);
      const cameraGroupSelectedRowSet = new Set(cameraGroupSelectedRow.map(String));

      const newData = listCameraBinding.data
        .filter(
          ({
            cameraType,
            deviceTypeName,
            key
          }: TCameraBinding & {
            key: string;
          }) =>
            !isThermalCameraInThermalOpticalDevice({
              cameraType,
              deviceTypeName
            }) && cameraGroupSelectedRowSet.has(key?.toString() || '')
        )
        .map(
          ({
            serialNumber
          }: TCameraBinding & {
            key: string;
          }) => serialNumber.toString()
        );

      if (isEqual(oldData, newData)) {
        return EHttpStatusCode.NOT_MODIFIED;
      }
      return await cameraGroupRepository.unbindCamerasFromCameraGroup({
        cameraSerials: newData.toString(),
        groupsId: Number(cameraGroupId)
      });
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'other',
        entity: t('camera.entity'),
        actionStr: t('actions.unassign'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.unassign')} ${t('camera.entity')}`,
        description: message
      });
      return EHttpStatusCode.BAD_REQUEST;
    }
  };

  const handleUnassignedEyeviewUser = async () => {
    if (!eyeviewUserSelectedRow) return EHttpStatusCode.NOT_FOUND;
    try {
      const oldData = currentCameraGroup.users.map((value: TEyeviewUserAssign) =>
        value.id.toString()
      );
      const newData = eyeviewUserSelectedRow.map((value: React.Key) => value.toString());
      if (isEqual(oldData, newData)) {
        return EHttpStatusCode.NOT_MODIFIED;
      }
      return await cameraGroupRepository.unassignedUsersFromCameraGroup({
        cameraGroupId: currentCameraGroup.id,
        userIds: newData
      });
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'other',
        entity: t('userPage.entity'),
        actionStr: t('actions.unassign'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.unassign')} ${t('userPage.entity')}`,
        description: message
      });
      return EHttpStatusCode.BAD_REQUEST;
    }
  };

  const handleIntervalRefreshCameraBindingData = async () => {
    const cameraGroupIdNumber = Number(cameraGroupId);
    if (!cameraGroupId || Number.isNaN(cameraGroupIdNumber)) return;

    const setLoadingState = (loading: boolean) => {
      setListCameraBinding((prev) => ({
        ...prev,
        loading: isInEdit ? false : loading
      }));
      setAstroDeviceList((prev) => ({
        ...prev,
        loading
      }));
      setUpsMonitorList((prev) => ({
        ...prev,
        loading
      }));
    };

    try {
      setLoadingState(true);

      const { data, code } = await cameraGroupRepository.getCameraGroupById(cameraGroupIdNumber);
      if (code === EHttpStatusCode.OK) {
        setCurrentCameraGroup((prev) => ({
          ...prev,
          cameras: isInEdit ? prev.cameras : data.cameras,
          devices: data.devices
        }));
        if (!isInEdit) {
          setOrderSelectValueDeviceTab(ESortingDevicePage.NO_SORT);
          setOrderSelectValueCameraTab(ESortingDevicePage.NO_SORT);
        }

        const dataWithKey = mapToCameraBindingListWithKey(data.cameras);

        const areListInOrders =
          data.cameras.filter(
            ({ deviceTypeName = '', cameraType = '' }: TCameraBinding) =>
              !(
                deviceTypeName.toUpperCase() === EDeviceType.THERMAL_OPTICAL_CAMERA &&
                cameraType.toLowerCase() === ECameraType.THERMAL
              )
          ).length === cameraGroupSelectedRow.length &&
          dataWithKey.length === listCameraBinding.data.length &&
          dataWithKey.every((camera, index) => camera.id === listCameraBinding.data[index].id);

        if (!areListInOrders && !isInEdit) {
          setWarningMessage(t('cameraGroupPage.sections.deviceBind.warning'));
          setTimeout(() => {
            setWarningMessage('');
          }, 4000);
        }
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('cameraGroupPage.entity'),
        identifier: cameraGroupId.toString(),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('cameraGroupPage.sections.deviceBind.title')}`,
        description: message
      });
    } finally {
      setLoadingState(false);
    }
  };

  const handleFormSubmit = async (values: TCameraGroupGeneralFieldType) => {
    const allSettledResult = await Promise.allSettled([
      handleUnbindCameraFromCameraGroup(),
      handleUnassignedEyeviewUser(),
      handleUpdateCameraGroup(values)
    ]);

    const hasSuccess = allSettledResult.some(
      (result) => result.status === 'fulfilled' && result.value === EHttpStatusCode.OK
    );
    const hasFailure = allSettledResult.some(
      (result) =>
        result.status === 'fulfilled' &&
        [EHttpStatusCode.NOT_FOUND, EHttpStatusCode.BAD_REQUEST].includes(result.value)
    );
    const allUnchanged = allSettledResult.every(
      (result) => result.status === 'fulfilled' && result.value === EHttpStatusCode.NOT_MODIFIED
    );

    const shouldShowSuccessNotification = hasSuccess || allUnchanged;
    const shouldNavigateToListing = allUnchanged || (hasSuccess && !hasFailure);

    if (shouldShowSuccessNotification) {
      openNotification({
        type: 'success',
        title: `${t('actions.update')} ${t('cameraGroupPage.entity')}`,
        description: t('components.success')
      });
    }

    if (shouldNavigateToListing) {
      if (!searchParams.get('page')) {
        searchParams.set('page', DEFAULT_PAGE_NUMBER.toString());
        setSearchParams(searchParams);
      }
      navigate({
        pathname: Routes.ECameraGroupRoutes.LISTING,
        search: searchParams.toString()
      });
    }
  };

  const handleCameraGroupSelectedRowChange = useCallback((value: React.Key[]) => {
    setCameraGroupSelectedRow(value);
    setIsInEdit(true);
  }, []);

  const handleCameraBindingListChange = useCallback(
    (value: { data: Array<TCameraBinding & { key: string }>; loading: boolean }) => {
      setListCameraBinding(value);
      setIsInEdit(true);
    },
    []
  );

  const handleCameraBindingSwitchChange = useCallback((value: SegmentedValue) => {
    setCameraBindingSwitchValue(
      ECameraBindingSwitchOption.Device === value
        ? ECameraBindingSwitchOption.Device
        : ECameraBindingSwitchOption.Camera
    );
  }, []);

  const handleEyeviewUserSelectedRowChange = useCallback(
    (value: React.Key[]) => setEyeviewUserSelectedRow(value),
    []
  );

  const handleLoadServiceNoteData = async ({
    search,
    page,
    sort,
    loadMore = false
  }: {
    search?: string;
    sort?: number;
    page: number;
    loadMore?: boolean;
  }) => {
    const cameraGroupIdNumber = Number(cameraGroupId);
    if (
      !cameraGroupId ||
      Number.isNaN(cameraGroupIdNumber) ||
      !hasMore.current ||
      serviceNoteList.loading
    ) {
      return;
    }
    try {
      startLoading();
      const {
        data,
        code,
        page: paging
      } = await cameraGroupRepository.getListServiceNoteByCameraGroupId({
        deviceGroupId: cameraGroupIdNumber,
        page,
        search: `*${search ?? debounceSearchServiceNoteValue}*`,
        size: SMALL_LIMIT,
        sort: `createdAt,${
          (sort ?? selectSortFilter) === ESortingServiceNotes.ORDER_BY_DATE_ASC ? 'asc' : 'desc'
        }`
      });

      if (code === EHttpStatusCode.OK) {
        setTotal(paging?.total ?? 0);
        if (loadMore) {
          if (data.length === 0) {
            hasMore.current = false;
          } else {
            pageNumber.current = page;

            setServiceNoteList((prev) => {
              const distinctData = Array.from(
                new Map([...prev.data, ...data].map((item) => [item.id, item])).values()
              );

              return {
                ...prev,
                data: distinctData
              };
            });
          }
        } else {
          if (data.length === 0) {
            hasMore.current = false;
          }
          setServiceNoteList((prev) => ({
            ...prev,
            data
          }));
        }
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('serviceNote.entity'),
        identifier: cameraGroupId.toString(),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('serviceNote.entity')}`,
        description: message
      });
    } finally {
      stopLoading();
    }
  };

  const handleLoadMore = async () => {
    await handleLoadServiceNoteData({
      page: pageNumber.current + 1,
      loadMore: true
    });
  };

  const handleAddNote = async (text: string) => {
    const cameraGroupIdNumber = Number(cameraGroupId);
    if (
      !cameraGroupId ||
      text.trim().length === 0 ||
      Number.isNaN(cameraGroupIdNumber) ||
      serviceNoteList.loading
    ) {
      return;
    }
    try {
      setTopLoading(true);
      const { data, code } = await cameraGroupRepository.addServiceNoteByCameraGroupId({
        deviceGroupId: cameraGroupIdNumber,
        text
      });

      if (code === EHttpStatusCode.OK) {
        infiniteScrollRef.current?.onScrollTop();
        openNotification({
          type: 'success',
          title: `${t('actions.create')} ${t('serviceNote.entity')}`,
          description: t('components.success')
        });

        setServiceNoteList((prev) => {
          const distinctData = Array.from(
            new Map([data, ...prev.data].map((item) => [item.id, item])).values()
          );

          return {
            ...prev,
            data: distinctData
          };
        });

        setTotal((prev) => prev + 1);
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'create',
        entity: t('serviceNote.entity'),
        identifier: cameraGroupId.toString(),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.create')} ${t('serviceNote.entity')}`,
        description: message
      });
    } finally {
      setTopLoading(false);
    }
  };

  const handleUpdateNote = async ({ id, text }: { id: number; text: string }) => {
    if (!cameraGroupId || text.trim().length === 0 || serviceNoteList.loading) {
      return;
    }
    try {
      startLoading();

      const { data, code } = await cameraGroupRepository.updateServiceNoteById({
        noteId: id,
        text
      });

      if (code === EHttpStatusCode.OK) {
        openNotification({
          type: 'success',
          title: `${t('actions.update')} ${t('serviceNote.entity')}`,
          description: t('components.success')
        });

        setServiceNoteList((prev) => ({
          ...prev,
          data: prev.data.map((v: TServiceNote) =>
            v.id !== id
              ? v
              : {
                  ...v,
                  createdBy: data.createdBy,
                  createdTime: data.createdTime,
                  modifiedBy: data.modifiedBy,
                  modifiedTime: data.modifiedTime,
                  text: data.text
                }
          )
        }));
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'update',
        entity: t('serviceNote.entity'),
        identifier: cameraGroupId.toString(),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.update')} ${t('serviceNote.entity')}`,
        description: message
      });
    } finally {
      stopLoading();
    }
  };

  const handleDeleteNode = async (id: number) => {
    const cameraGroupIdNumber = Number(cameraGroupId);
    if (!cameraGroupId || Number.isNaN(cameraGroupIdNumber) || serviceNoteList.loading) {
      return;
    }
    try {
      startLoading();
      const code = await cameraGroupRepository.deleteServiceNoteById(id);

      if (code === EHttpStatusCode.OK) {
        openNotification({
          type: 'success',
          title: `${t('actions.delete')} ${t('serviceNote.entity')}`,
          description: t('components.success')
        });

        // Refetch current page to get new data after delete
        const { data: newLoadData, code: statusCode } =
          await cameraGroupRepository.getListServiceNoteByCameraGroupId({
            deviceGroupId: cameraGroupIdNumber,
            page: pageNumber.current,
            search: `*${debounceSearchServiceNoteValue}*`,
            size: SMALL_LIMIT,
            sort: `createdAt,${
              selectSortFilter === ESortingServiceNotes.ORDER_BY_DATE_ASC ? 'asc' : 'desc'
            }`
          });

        if (statusCode === EHttpStatusCode.OK) {
          const data = serviceNoteList.data.filter((d) => d.id !== id);

          const distinctData = Array.from(
            new Map([...data, ...newLoadData].map((item) => [item.id, item])).values()
          );

          setServiceNoteList((prev) => ({
            ...prev,
            data: distinctData
          }));

          setTotal((prev) => prev - 1);
        }
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'delete',
        entity: t('serviceNote.entity'),
        identifier: cameraGroupId.toString(),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.delete')} ${t('serviceNote.entity')}`,
        description: message
      });
    } finally {
      stopLoading();
    }
  };

  const handleClickRefreshServiceNote = async () => {
    pageNumber.current = DEFAULT_PAGE_NUMBER;
    infiniteScrollRef.current?.onScrollTop();
    hasMore.current = true;
    handleLoadServiceNoteData({
      search: '',
      page: DEFAULT_PAGE_NUMBER,
      sort: ESortingServiceNotes.ORDER_BY_DATE_DESC
    });
    setSearchServiceNoteValue('');
    setServiceNoteList((prev) => ({
      ...prev,
      data: []
    }));
    setSelectSortFilter(ESortingServiceNotes.ORDER_BY_DATE_DESC);
  };

  /**
   * USE-EFFECT REGION
   */

  useEffect(() => {
    resetBindForm();
  }, []);

  useEffect(() => {
    if (isAllowedViewServiceNote) {
      pageNumber.current = DEFAULT_PAGE_NUMBER;
      hasMore.current = true;
      setServiceNoteList((prev) => ({
        ...prev,
        data: []
      }));
      handleLoadServiceNoteData({
        search: debounceSearchServiceNoteValue,
        page: pageNumber.current
      });
    }
  }, [debounceSearchServiceNoteValue, isAllowedViewServiceNote]);

  useSkipFirstEffect(() => {
    if (isAllowedViewServiceNote) {
      pageNumber.current = DEFAULT_PAGE_NUMBER;
      hasMore.current = true;
      setServiceNoteList((prev) => ({
        ...prev,
        data: []
      }));
      handleLoadServiceNoteData({
        page: pageNumber.current,
        sort: selectSortFilter
      });
    }
  }, [selectSortFilter, isAllowedViewServiceNote]);

  useEffect(() => {
    if (currentCameraGroupLoader) {
      setCurrentCameraGroup(currentCameraGroupLoader);
    }
  }, [currentCameraGroupLoader]);

  useInterval(
    () => {
      handleIntervalRefreshCameraBindingData();
    },
    INTERVAL_TIME_REFRESH_DEVICE_GROUP_BINDING,
    true
  );

  useEffect(() => {
    if (currentCameraGroup.cameras) {
      setListCameraBinding((prev) => ({
        ...prev,
        data: mapToCameraBindingListWithKey(currentCameraGroup.cameras)
      }));
      setCameraGroupSelectedRow(
        currentCameraGroup.cameras
          .filter(
            ({ deviceTypeName = '', cameraType = '' }: TCameraBinding) =>
              !(
                deviceTypeName.toUpperCase() === EDeviceType.THERMAL_OPTICAL_CAMERA &&
                cameraType.toLowerCase() === ECameraType.THERMAL
              )
          )
          .map((value) => value.id.toString())
      );
    }
  }, [currentCameraGroup.cameras]);

  /**
   * Effect devices property changes, update the selected row
   */
  useEffect(() => {
    if (currentCameraGroup.devices) {
      const updateDeviceList = (
        deviceType: EDeviceType,
        setList: React.Dispatch<React.SetStateAction<DataList<TOtherDevice[]>>>,
        setSelectedRow: React.Dispatch<React.SetStateAction<React.Key[]>>
      ) => {
        const filteredList = currentCameraGroup.devices.filter(
          (device: TOtherDevice) => device.typeName === deviceType
        );
        setList({
          data: filteredList,
          loading: false
        });
        setSelectedRow(filteredList.map((device: TOtherDevice) => device.serialNumber));
      };

      updateDeviceList(EDeviceType.ASTRO_CAMERA, setAstroDeviceList, setAstroDeviceSelectedRow);
      updateDeviceList(EDeviceType.UPS_MONITOR, setUpsMonitorList, setUpsMonitorSelectedRow);
    }
  }, [currentCameraGroup.devices]);

  /**
   * Effect users property changes, update the selected row
   */
  useEffect(() => {
    if (currentCameraGroup.users) {
      const userAssignments = currentCameraGroup.users;
      setUserAssignmentList({
        data: orderBy(userAssignments, 'username', 'asc'),
        loading: false
      });
      setEyeviewUserSelectedRow(userAssignments.map((user: TEyeviewUserAssign) => user.id));
    }
  }, [currentCameraGroup.users]);

  useEffect(() => {
    if (actionStatus.success && actionStatus.type) {
      switch (actionStatus.type) {
        case 'assign': {
          handleRefetchEyeviewUserAssignment();
          break;
        }
        case 'sync':
        case 'bind': {
          handleRefetchCameraGroupBinding();
          break;
        }
      }
      setOrderSelectValueDeviceTab(ESortingDevicePage.NO_SORT);
      setOrderSelectValueCameraTab(ESortingDevicePage.NO_SORT);
      onResetActionStatus();
    }
  }, [actionStatus]);

  useEffect(() => {
    handleFetchCameraGroupType();
  }, []);

  useEffectOnce(() => {
    if (currentCameraGroup && cameraGroupTypes.data.length > 0) {
      const data =
        currentCameraGroup.address && isJsonString(currentCameraGroup.address)
          ? JSON.parse(currentCameraGroup.address)
          : {
              street: currentCameraGroup?.address,
              city: '',
              state: '',
              zip: ''
            };
      formEditCameraGroup.setFieldsValue({
        name: currentCameraGroup?.name,
        accountId: currentCameraGroup?.accountId,
        cameraGroupTypeId: currentCameraGroup?.type?.id,
        comment: currentCameraGroup?.comment ?? '',
        street: data?.street ?? '',
        city: data?.city ?? '',
        state: data?.state ?? '',
        zip: data?.zip ?? '',
        latitude: parseENotationNumberToString(currentCameraGroup?.lat),
        longitude: parseENotationNumberToString(currentCameraGroup?.lon),
        locationNumber: currentCameraGroup?.locationNumber ?? '',
        siteType: currentCameraGroup?.siteType ?? ''
      });
    }
  }, [currentCameraGroup, cameraGroupTypes.data]);

  useEffect(() => {
    if (
      [
        ESortingDevicePage.NO_SORT,
        ESortingDevicePage.ORDER_ASC_BY_CAMERA_NAME,
        ESortingDevicePage.ORDER_DESC_BY_CAMERA_NAME,
        ESortingDevicePage.ORDER_ASC_BY_SERIAL_NUMBER,
        ESortingDevicePage.ORDER_DESC_BY_SERIAL_NUMBER
      ].includes(orderSelectValueCameraTab)
    ) {
      setListCameraBinding((prev) => ({
        ...prev,
        data: sortDeviceBinding({
          cameraBinding: mapToCameraBindingListWithKey(currentCameraGroup.cameras),
          sortKey: orderSelectValueCameraTab
        })
      }));
    }
  }, [orderSelectValueCameraTab, currentCameraGroup.cameras]);

  useEffectOnce(() => {
    if (isActive) {
      loader.complete();
    }
  }, [isActive]);

  return {
    isAllowedViewServiceNote,
    isInEdit,
    infiniteScrollRef,
    warningMessage,
    userAssignmentList,
    orderSelectValueDeviceTab,
    orderSelectValueCameraTab,
    isAllPublicIPMatches,
    formEditCameraGroup,
    cameraGroupId: cameraGroupId ? parseInt(cameraGroupId) : null,
    currentCameraGroup,
    cameraGroupTypes,
    cameraBindingSwitchValue,
    listCameraBinding,
    isShowRefreshButton,
    astroDeviceList,
    upsMonitorList,
    cameraGroupSelectedRow,
    astroDeviceSelectedRow,
    eyeviewUserSelectedRow,
    upsMonitorSelectedRow,
    deviceBindingSummary: {
      totalCameras: listCameraBinding.data.length,
      totalDevices: new Set(
        listCameraBinding.data.map((value: TCameraBinding & { key: string }) => value.serialNumber)
      ).size
    },
    serviceNoteList,
    total,
    focusingID,
    topLoading,
    selectSortFilter,
    searchServiceNoteValue,
    hasMore: hasMore.current,
    onCancel: useCallback(() => {
      navigate(
        {
          pathname: Routes.ECameraGroupRoutes.LISTING,
          search: searchParams.toString()
        },
        { replace: true }
      );
    }, [searchParams]),
    onCameraBindingListChange: handleCameraBindingListChange,
    onCameraBindingRefresh: handleCameraBindingRefresh,
    onUpsMonitorRefresh: handleUpsMonitorRefresh,
    onCameraGroupSelectedRowChange: handleCameraGroupSelectedRowChange,
    onAstroDeviceSelectedRowChange: handleAstroDeviceSelectedRowChange,
    onUpsMonitorSelectedRowChange: handleUpsMonitorSelectedRowChange,
    onEyeviewUserSelectedRowChange: handleEyeviewUserSelectedRowChange,
    onFormSubmit: handleFormSubmit,
    onAstroDeviceRefresh: handleAstroDeviceRefresh,
    onCameraBindingSwitchChange: handleCameraBindingSwitchChange,
    onOrderTableCameraBindingChange: (value: number) => {
      if (cameraBindingSwitchValue === ECameraBindingSwitchOption.Device) {
        setOrderSelectValueCameraTab(value);
        setOrderSelectValueDeviceTab(value);
      } else {
        setOrderSelectValueCameraTab(value);
        setOrderSelectValueDeviceTab(
          value === ESortingDevicePage.ORDER_ASC_BY_CAMERA_NAME ? null : value
        );
      }
      setIsInEdit(true);
    },
    onInEditChange: (value: boolean) => setIsInEdit(value),
    onFocusingIDChange: (value?: number) => setFocusingID(value),
    onAddNote: handleAddNote,
    onEditNote: handleUpdateNote,
    onDeleteNote: handleDeleteNode,
    onSelectSortFilterChange: (value: number) => setSelectSortFilter(value),
    onClickRefreshServiceNote: handleClickRefreshServiceNote,
    onServiceNoteSearchChange: (value: string) => setSearchServiceNoteValue(value),
    onLoadMore: handleLoadMore
  };
};
