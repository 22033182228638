import React, { useState } from 'react';
import { Button, Form, Input, Result } from 'antd';

import LogoEyeCast from 'assets/images/eyecast-logo.png';
import { useAppUtil } from 'context/UtilContext';
import { EStorageKey, Routes } from 'enums';
import { SwitchLanguage } from 'presentation/components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLoginPageController } from './LoginPageController';
import styles from './loginPage.module.scss';
import { useEOSSContext } from 'context/EOSSContext';
import { parseLocalUser } from 'utils/common';
import useFlag from 'hooks/useFlag';
import EyeOutlined from '@ant-design/icons/lib/icons/EyeOutlined';
import EyeInvisibleOutlined from '@ant-design/icons/lib/icons/EyeInvisibleOutlined';

const MAX_RETRY_TIMES = 3;

type FieldType = {
  username: string;
  password: string;
  remember?: string;
};

function LoginPageView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openNotification, destroy } = useAppUtil();
  const { login } = useLoginPageController();
  const { initStatus, refetchInitCount } = useEOSSContext();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [isSubmitting, markAsSubmitting, markAsNotSubmitting] = useFlag(false);

  const authError = sessionStorage.getItem(EStorageKey.AUTH_ERROR);
  const freezingActivityError = sessionStorage.getItem(EStorageKey.FREEZING_ERROR);

  const userDataFromLocal = parseLocalUser(sessionStorage.getItem(EStorageKey.EOSS_CURRENT_USER));

  const onFinish = async (values: FieldType) => {
    if (isSubmitting) return;
    try {
      markAsSubmitting();
      if (localStorage.getItem(EStorageKey.EOSS_CURRENT_USER)) {
        window.location.reload();
        return;
      }
      const successOrError = await login(values);

      if (successOrError.isLeft()) {
        let message = '';
        switch (successOrError.error?.error) {
          case 'USERNAME_OR_PASSWORD_INCORRECT':
            message = t('errors.userNameOrPasswordIncorrect');
            break;
          case 'DISABLED_RESOURCE':
            message = t('errors.disableResource', {
              entity: t('adminUserPage.entity')
            });
            break;
          default:
            message = t('errors.unknown');
            break;
        }
        return openNotification({
          type: 'error',
          title: `${t('actions.login')}`,
          description: message
        });
      }

      //login success
      destroy();
      navigate(Routes.EHomeRoutes.INDEX, { replace: true });
      openNotification({
        type: 'success',
        title: t('welcome.title'),
        description: t('welcome.description')
      });
    } finally {
      markAsNotSubmitting();
    }
  };

  useEffect(() => {
    if (!initStatus && refetchInitCount > 0 && refetchInitCount < MAX_RETRY_TIMES) {
      openNotification({
        type: 'error',
        title: `${t('actions.login')}`,
        description: t('errors.unknown')
      });
    }
  }, [initStatus, refetchInitCount]);

  useEffect(() => {
    if (authError) {
      let message = t('errors.expiredToken');

      if (authError === 'DISABLED_RESOURCE') {
        message = t('errors.disableResource', {
          entity: t('adminUserPage.entity')
        });
      }
      openNotification({
        type: 'error',
        title: `${t('components.sessionExpired')}`,
        description: message
      });
      sessionStorage.removeItem(EStorageKey.REDIRECT_TO);
      sessionStorage.removeItem(EStorageKey.AUTH_ERROR);
    }
  }, [authError]);

  useEffect(() => {
    if (freezingActivityError) {
      openNotification({
        type: 'error',
        title: t('components.sessionExpired'),
        description: t('errors.freezingActivity'),
        onClose: () => {
          sessionStorage.removeItem(EStorageKey.FREEZING_ERROR);
        }
      });
    }
  }, [freezingActivityError]);

  return (
    <div
      className={`h-100 px-2 d-flex align-items-center justify-content-center ${styles.screenContainer}`}
    >
      {refetchInitCount < MAX_RETRY_TIMES ? (
        <div style={{ flex: 1, maxWidth: 494 }}>
          <div>
            <div
              className="text-center p-3 bg-black"
              style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
            >
              <img
                src={LogoEyeCast}
                className="animate__animated animate__zoomIn cursor-pointer mb-3"
                alt="logo"
                width={137}
                height={28}
              />
              <div className={styles.txtWelcome}>{t('loginPage.welcome')}</div>
            </div>
            <div className={styles.formContainer}>
              <div className={`${styles.txtLogin} mb-4`}>{t('loginPage.title')}</div>
              <Form name="login-form" onFinish={onFinish} autoComplete="off">
                <Form.Item<FieldType>
                  name="username"
                  rules={[{ required: true, message: t('form.username.rules.required') }]}
                >
                  <Input
                    id="username_field"
                    className={styles.inputUsername}
                    placeholder={t('form.username.placeholder')}
                  />
                </Form.Item>

                <Form.Item<FieldType>
                  name="password"
                  rules={[{ required: true, message: t('form.password.rules.required') }]}
                >
                  <Input.Password
                    id="password_field"
                    className={styles.inputPassword}
                    placeholder={t('form.password.placeholder')}
                    visibilityToggle={{
                      visible: passwordVisible
                    }}
                    iconRender={(_) =>
                      passwordVisible ? (
                        <div
                          className={`${styles.btnEye}`}
                          onMouseDownCapture={(e) => {
                            e.stopPropagation();
                            setPasswordVisible(false);
                          }}
                          onMouseUpCapture={(e) => {
                            e.stopPropagation();
                            setTimeout(() => {
                              setPasswordVisible(false);
                            }, 2000);
                          }}
                        >
                          <EyeOutlined />
                        </div>
                      ) : (
                        <div
                          className={`${styles.btnEye}`}
                          onMouseDownCapture={(e) => {
                            e.stopPropagation();
                            setPasswordVisible(true);
                          }}
                          onMouseUpCapture={(e) => {
                            e.stopPropagation();
                            setTimeout(() => {
                              setPasswordVisible(false);
                            }, 2000);
                          }}
                        >
                          <EyeInvisibleOutlined />
                        </div>
                      )
                    }
                  />
                </Form.Item>

                <Button
                  id="login_button"
                  className={`${styles.btnLogin} btn-fill`}
                  htmlType="submit"
                >
                  {t('loginPage.btnLogin')}
                </Button>
              </Form>
              <div
                className={styles.txtForgot}
                onClick={() => {
                  openNotification({
                    type: 'info',
                    title: t('components.comingSoon')
                  });
                }}
                // TODO will implement in EP2
                // onClick={() => navigate(Routes.EAuthRoutes.RESET_PASSWORD)}
              >
                {t('resetPasswordPage.forgotPassword')}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <div style={{ width: 'fit-content' }}>
              <SwitchLanguage />
            </div>
          </div>
        </div>
      ) : (
        <Result
          status={'500'}
          title="Server Maintaining"
          subTitle="Please reload page & login again!"
        />
      )}

      <div className={`${styles.txtVersion} text-center`}>
        {process.env.REACT_APP_BASE_ENV}-{process.env.REACT_APP_BASE_VERSION}
      </div>
    </div>
  );
}

export default LoginPageView;
