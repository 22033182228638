import React, { memo, useEffect, useMemo, useState } from 'react';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DragAndDropIcon } from 'assets/icons';
import { TOOLTIP_DELAY } from 'constant';
import {
  EAdminTypeDisplayName,
  ECameraBindingSwitchOption,
  EDeviceStatus,
  ERoleKey,
  EStorageKey,
  ESubFeatureKey,
  Routes
} from 'enums';
import { usePathPermission } from 'hooks/usePermission';
import type { TCameraBinding, TCameraBindingServer } from 'models';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  checkDeviceStatus,
  concatCameraNamePrefix,
  isOpticalCameraInThermalOpticalDevice,
  isOverlayCamera,
  isOverlayCameraInThermalOpticalDevice,
  isThermalCamera,
  isThermalCameraInThermalOpticalDevice,
  isValidNumber,
  parseLocalUser,
  secondaryBVRServerDisplay,
  splitLastContact
} from 'utils/common';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import styles from './tableCameraBinding.module.scss';
import { SmartTooltip } from 'presentation/components';
import { useLoaderContext } from 'context/LoaderContext';

type Props = {
  listCameraBinding: {
    data: Array<TCameraBinding & { key: string }>;
    loading: boolean;
  };
  cameraBindingSwitchValue: ECameraBindingSwitchOption;
  cameraGroupSelectedRow: React.Key[];
  onCameraGroupSelectedRowChange: (value: React.Key[]) => void;
  onCameraBindingListChange: (value: {
    data: Array<
      TCameraBinding & {
        key: string;
      }
    >;
    loading: boolean;
  }) => void;
};

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
    setNodeRef,
    setActivatorNodeRef
  } = useSortable({
    id: props['data-row-key']
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 999 } : {})
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <div
                ref={setActivatorNodeRef}
                {...listeners}
                style={{
                  touchAction: 'none',
                  cursor: 'move',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <DragAndDropIcon />
              </div>
            )
          });
        }
        return child;
      })}
    </tr>
  );
};

function TableCameraBinding({
  listCameraBinding,
  cameraBindingSwitchValue,
  cameraGroupSelectedRow,
  onCameraGroupSelectedRowChange,
  onCameraBindingListChange
}: Props) {
  const { t } = useTranslation();

  const { loader } = useLoaderContext();

  const haveCameraGroupUpdateAssignmentPermission = usePathPermission(
    Routes.ECameraGroupRoutes.LISTING,
    ERoleKey.UPDATE,
    ESubFeatureKey.ASSIGNMENT
  ).allowed;

  const [clickedId, setClickedId] = useState<number>();

  useEffect(() => {
    return () => {
      setClickedId(undefined);
    };
  }, []);

  const isCustomerDomainAdmin = useMemo(
    () =>
      parseLocalUser(localStorage.getItem(EStorageKey.EOSS_CURRENT_USER))?.adminTypeDisplayName ===
      EAdminTypeDisplayName.DOMAIN_ADMIN,
    []
  );

  const isDeviceSegmented = useMemo(
    () => cameraBindingSwitchValue === ECameraBindingSwitchOption.Device,
    [cameraBindingSwitchValue]
  );

  const columnsTableCameraBinding: ColumnsType<TCameraBinding> = useMemo(
    () => [
      {
        title: t('cameraGroupPage.cameraBindingTable.serialNumber').toUpperCase(),
        dataIndex: 'serialNumber',
        key: 'serialNumber',
        render: (value: string, { deviceTypeName, ...record }: TCameraBinding) => {
          if (
            !isDeviceSegmented &&
            isOpticalCameraInThermalOpticalDevice({
              cameraType: record.cameraType,
              deviceTypeName
            })
          ) {
            const thermalCamera = listCameraBinding.data.find(
              ({ serialNumber = '', cameraType = '' }: TCameraBinding & { key: string }) =>
                serialNumber === record?.serialNumber && isThermalCamera(cameraType)
            );
            const overlayCamera = listCameraBinding.data.find(
              ({ serialNumber = '', cameraType = '' }: TCameraBinding & { key: string }) =>
                serialNumber === record?.serialNumber && isOverlayCamera(cameraType)
            );

            const renderData = [
              { serialNumber: value, idx: record.idx },
              { serialNumber: thermalCamera?.serialNumber, idx: thermalCamera?.idx }
            ];

            overlayCamera &&
              renderData.push({
                serialNumber: overlayCamera?.serialNumber,
                idx: overlayCamera?.idx
              });

            return (
              <div className="text-nowrap d-flex flex-column gap-2">
                {renderData.map(({ serialNumber = '', idx = 0 }, index) => (
                  <div key={index}>
                    <Link
                      data-name="serialNumber"
                      to={`${Routes.EDevicesRoutes.INDEX}/${(deviceTypeName ?? '').toLowerCase()}/${
                        record.deviceId
                      }?search=${value}`}
                      style={{
                        pointerEvents: clickedId === record.deviceId ? 'none' : 'auto'
                      }}
                      onClick={() => {
                        loader.start();
                        setClickedId(record.deviceId);
                      }}
                    >
                      {serialNumber}
                      {`-${isValidNumber(idx) ? Number(idx) + 1 : ''}`}
                    </Link>
                  </div>
                ))}
              </div>
            );
          }
          return (
            <Link
              data-name="serialNumber"
              to={`${Routes.EDevicesRoutes.INDEX}/${(deviceTypeName ?? '').toLowerCase()}/${
                record.deviceId
              }?search=${value}`}
              style={{
                pointerEvents: clickedId === record.deviceId ? 'none' : 'auto'
              }}
              onClick={() => {
                loader.start();
                setClickedId(record.deviceId);
              }}
            >
              {value}
              {isDeviceSegmented ? '' : `-${record.idx + 1}`}
            </Link>
          );
        }
      },
      {
        title: t(
          `cameraGroupPage.cameraBindingTable.${isDeviceSegmented ? 'type' : 'cameraName'}`
        ).toUpperCase(),
        dataIndex: isDeviceSegmented ? 'deviceTypeName' : 'name',
        key: isDeviceSegmented ? 'deviceTypeName' : 'name',
        render: (value: string, record: TCameraBinding) => {
          if (
            !isDeviceSegmented &&
            isOpticalCameraInThermalOpticalDevice({
              cameraType: record.cameraType,
              deviceTypeName: record.deviceTypeName
            })
          ) {
            const thermalCamera = listCameraBinding.data.find(
              ({ serialNumber = '', cameraType = '' }: TCameraBinding & { key: string }) =>
                serialNumber === record?.serialNumber && isThermalCamera(cameraType)
            );
            const overlayCamera = listCameraBinding.data.find(
              ({ serialNumber = '', cameraType = '' }: TCameraBinding & { key: string }) =>
                serialNumber === record?.serialNumber && isOverlayCamera(cameraType)
            );

            const renderData = [
              {
                cameraName: value,
                deviceTypeName: record.deviceTypeName,
                isOptical: true,
                isOverlay: false
              },
              {
                cameraName: thermalCamera?.name,
                deviceTypeName: thermalCamera?.deviceTypeName,
                isOptical: false,
                isOverlay: false
              }
            ];

            overlayCamera &&
              renderData.push({
                cameraName: overlayCamera?.name,
                deviceTypeName: overlayCamera?.deviceTypeName,
                isOptical: false,
                isOverlay: true
              });

            return (
              <div className="d-flex flex-column gap-2">
                {renderData.map(
                  (
                    { cameraName = '', deviceTypeName = '', isOptical, isOverlay },
                    index: number
                  ) => (
                    <div key={index}>
                      <SmartTooltip
                        name="name"
                        delayTime={TOOLTIP_DELAY}
                        TooltipComponentProps={{
                          trigger: 'hover'
                        }}
                      >
                        {!isDeviceSegmented
                          ? concatCameraNamePrefix({
                              serialNumber: record.serialNumber,
                              cameraName,
                              isDualSensor: true,
                              isOpticalCamera: isOptical,
                              isOverlay
                            })
                          : deviceTypeName}
                      </SmartTooltip>
                    </div>
                  )
                )}
              </div>
            );
          }
          return (
            <SmartTooltip
              name={isDeviceSegmented ? 'deviceTypeName' : 'name'}
              delayTime={TOOLTIP_DELAY}
              TooltipComponentProps={{
                trigger: 'hover'
              }}
            >
              {!isDeviceSegmented
                ? concatCameraNamePrefix({
                    serialNumber: record.serialNumber,
                    cameraName: value,
                    isDualSensor: false
                  })
                : value}
            </SmartTooltip>
          );
        },
        ellipsis: {
          showTitle: false
        }
      },
      {
        title: t('cameraGroupPage.cameraBindingTable.status').toUpperCase(),
        dataIndex: 'lastContactAt',
        key: 'lastContactAt',
        render: (value: number, record: TCameraBinding) => {
          const isDeviceOnline = checkDeviceStatus(value);
          if (
            !isDeviceSegmented &&
            isOpticalCameraInThermalOpticalDevice({
              cameraType: record.cameraType,
              deviceTypeName: record.deviceTypeName
            })
          ) {
            const thermalCamera = listCameraBinding.data.find(
              ({ serialNumber = '', cameraType = '' }: TCameraBinding & { key: string }) =>
                serialNumber === record?.serialNumber && isThermalCamera(cameraType)
            );
            const overlayCamera = listCameraBinding.data.find(
              ({ serialNumber = '', cameraType = '' }: TCameraBinding & { key: string }) =>
                serialNumber === record?.serialNumber && isOverlayCamera(cameraType)
            );

            const isThermalCameraOnline = thermalCamera?.lastContactAt
              ? checkDeviceStatus(thermalCamera.lastContactAt)
              : false;

            const renderData = [isDeviceOnline, isThermalCameraOnline];

            const isOverlayCameraOnline = overlayCamera?.lastContactAt
              ? checkDeviceStatus(overlayCamera.lastContactAt)
              : false;

            overlayCamera && renderData.push(isOverlayCameraOnline);

            return (
              <div data-name="status" className="d-flex flex-column gap-2">
                {renderData.map((isOnline: boolean, index) => (
                  <span
                    key={index}
                    className="fw-medium"
                    style={{ color: isDeviceOnline ? 'var(--sub-5)' : 'var(--sub-3)' }}
                  >
                    {isOnline ? EDeviceStatus.ONLINE : EDeviceStatus.OFFLINE}
                  </span>
                ))}
              </div>
            );
          }
          return (
            <span
              data-name="status"
              className="fw-medium"
              style={{ color: isDeviceOnline ? 'var(--sub-5)' : 'var(--sub-3)' }}
            >
              {isDeviceOnline ? EDeviceStatus.ONLINE : EDeviceStatus.OFFLINE}
            </span>
          );
        }
      },
      {
        title: t('cameraGroupPage.cameraBindingTable.bvr').toUpperCase(),
        dataIndex: 'servers',
        key: 'servers',
        render: (value: TCameraBindingServer[], record: TCameraBinding) => {
          const isCameraOnline = checkDeviceStatus(record.lastContactAt);

          const primaryBvrServer = value[0]?.ip;

          if (!primaryBvrServer) {
            return <span data-name="servers"></span>;
          }

          const secondaryServerDisplay = secondaryBVRServerDisplay({
            bvrConnectedIp: record.bvrConnected,
            secondaryBvrServerIp: value[1]?.ip,
            tertiaryBvrServerIp: value[2]?.ip
          });

          const hasOverlayCamera =
            listCameraBinding.data.find(
              ({ serialNumber = '', cameraType = '' }: TCameraBinding & { key: string }) =>
                serialNumber === record?.serialNumber && isOverlayCamera(cameraType)
            ) !== undefined;

          const renderBVR = () => {
            return (
              <span data-name="servers">
                <span
                  style={{
                    color: `${
                      record.bvrConnected === primaryBvrServer && isCameraOnline
                        ? 'var(--sub-5)'
                        : ''
                    } `
                  }}
                >
                  {primaryBvrServer}
                </span>
                {secondaryServerDisplay && (
                  <span>
                    {', '}
                    <span
                      style={{
                        color: `${
                          record.bvrConnected === secondaryServerDisplay && isCameraOnline
                            ? 'var(--sub-5)'
                            : ''
                        } `
                      }}
                    >
                      {secondaryServerDisplay}
                    </span>
                  </span>
                )}
              </span>
            );
          };

          if (
            !isDeviceSegmented &&
            isOpticalCameraInThermalOpticalDevice({
              cameraType: record.cameraType,
              deviceTypeName: record.deviceTypeName
            })
          ) {
            return (
              <div data-name="servers">
                <div className="mb-2">{renderBVR()}</div>
                <div>{renderBVR()}</div>
                {hasOverlayCamera && <div>{renderBVR()}</div>}
              </div>
            );
          }
          return <span data-name="servers">{renderBVR()}</span>;
        }
      },
      {
        title: t('cameraGroupPage.cameraBindingTable.lastContact').toUpperCase(),
        dataIndex: 'lastContactAt',
        key: 'lastContactAt',
        render: (value: number, record: TCameraBinding) => {
          if (
            !isDeviceSegmented &&
            isOpticalCameraInThermalOpticalDevice({
              cameraType: record.cameraType,
              deviceTypeName: record.deviceTypeName
            })
          ) {
            const thermalCamera = listCameraBinding.data.find(
              ({ serialNumber = '', cameraType = '' }: TCameraBinding & { key: string }) =>
                serialNumber === record?.serialNumber && isThermalCamera(cameraType)
            );

            const renderData = [
              splitLastContact(value),
              splitLastContact(thermalCamera?.lastContactAt)
            ];

            const overlayCamera = listCameraBinding.data.find(
              ({ serialNumber = '', cameraType = '' }: TCameraBinding & { key: string }) =>
                serialNumber === record?.serialNumber && isOverlayCamera(cameraType)
            );

            if (overlayCamera) {
              renderData.push(splitLastContact(overlayCamera?.lastContactAt));
            }

            return (
              <div className="d-flex flex-column gap-2">
                {renderData.map(({ date, time }, index) => (
                  <div key={index} data-name="lastContactAt">
                    <span>{date}</span>
                    <span className="text-nowrap">{time}</span>
                  </div>
                ))}
              </div>
            );
          }

          const { date, time } = splitLastContact(value);

          return (
            <div data-name="lastContactAt">
              <span>{date}</span>
              <span className="text-nowrap">{time}</span>
            </div>
          );
        }
      },
      {
        title: t('cameraGroupPage.cameraBindingTable.publicIP').toUpperCase(),
        dataIndex: 'publicIp',
        key: 'publicIp',
        render: (value: string, record: TCameraBinding) => {
          if (
            !isDeviceSegmented &&
            isOpticalCameraInThermalOpticalDevice({
              cameraType: record.cameraType,
              deviceTypeName: record.deviceTypeName
            })
          ) {
            const thermalCamera = listCameraBinding.data.find(
              ({ serialNumber = '', cameraType = '' }: TCameraBinding & { key: string }) =>
                serialNumber === record?.serialNumber && isThermalCamera(cameraType)
            );
            const overlayCamera = listCameraBinding.data.find(
              ({ serialNumber = '', cameraType = '' }: TCameraBinding & { key: string }) =>
                serialNumber === record?.serialNumber && isOverlayCamera(cameraType)
            );

            const renderData = [value, thermalCamera?.publicIp];

            if (overlayCamera) {
              renderData.push(overlayCamera?.publicIp);
            }

            return (
              <div data-name="publicIp" className="d-flex flex-column gap-2">
                {renderData.map((value, index) => (
                  <div key={index} className="text-nowrap">
                    {value}
                  </div>
                ))}
              </div>
            );
          }

          return (
            <div data-name="publicIp" className="text-nowrap">
              {value}
            </div>
          );
        }
      },
      {
        key: 'sort',
        title: t('cameraGroupPage.cameraBindingTable.order').toUpperCase(),
        fixed: listCameraBinding.data.length > 0 ? 'right' : undefined,
        width: 60
      }
    ],
    [isDeviceSegmented, listCameraBinding.data, clickedId]
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = listCameraBinding.data.findIndex(
        (i: TCameraBinding & { key: string }) => i.key === active.id
      );
      const overIndex = listCameraBinding.data.findIndex(
        (i: TCameraBinding & { key: string }) => i.key === over?.id
      );
      onCameraBindingListChange({
        data: arrayMove(listCameraBinding.data, activeIndex, overIndex),
        loading: false
      });
    }
  };

  const checkGroupIsNew = (serialNumber: string): boolean => {
    const getItem = sessionStorage.getItem(EStorageKey.CAMERA_GROUP_BINDING_KEYS);
    const listKeys: Array<string> = getItem ? JSON.parse(getItem) : [];
    if (listKeys.length > 0) {
      return Boolean(listKeys.find((key) => key.split(',').includes(serialNumber)));
    }
    return false;
  };

  useEffect(() => {
    const findRow = document.querySelector('.new-group');
    if (findRow) {
      findRow.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [listCameraBinding]);

  return (
    <DndContext
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      onDragEnd={onDragEnd}
      autoScroll={{ enabled: false }}
    >
      <SortableContext
        items={listCameraBinding.data.map((item: TCameraBinding & { key: string }) => item.key)}
        strategy={verticalListSortingStrategy}
        disabled={!haveCameraGroupUpdateAssignmentPermission}
      >
        <Table
          id="deviceGroupPage_deviceGroupBindingTable"
          tableLayout="auto"
          className={styles.cameraBindingTableContainer}
          components={{
            body:
              haveCameraGroupUpdateAssignmentPermission &&
              Array.from(new Set(listCameraBinding.data.map((value) => value.serialNumber)))
                .length > 1
                ? {
                    row: Row
                  }
                : {}
          }}
          rowKey="key"
          rowHoverable={false}
          columns={
            isCustomerDomainAdmin
              ? columnsTableCameraBinding.filter(
                  (item) => item.title !== t('cameraGroupPage.cameraBindingTable.bvr').toUpperCase()
                )
              : columnsTableCameraBinding
          }
          dataSource={listCameraBinding.data
            .filter(
              (value: TCameraBinding & { key: string }) =>
                !isThermalCameraInThermalOpticalDevice({
                  cameraType: value.cameraType,
                  deviceTypeName: value.deviceTypeName
                }) &&
                !isOverlayCameraInThermalOpticalDevice({
                  cameraType: value.cameraType,
                  deviceTypeName: value.deviceTypeName
                })
            )
            .map((value: TCameraBinding & { key: string }, index: number) => ({
              ...value,
              index
            }))}
          scroll={{
            x: listCameraBinding.data.length > 0 ? 'auto' : undefined
          }}
          rowSelection={{
            columnTitle: t('cameraGroupPage.otherDeviceTable.assigned').toUpperCase(),
            type: 'checkbox',
            hideSelectAll: true,
            columnWidth: 60,
            selectedRowKeys: cameraGroupSelectedRow,
            onChange: onCameraGroupSelectedRowChange,
            getCheckboxProps: () => ({
              disabled: !haveCameraGroupUpdateAssignmentPermission
            })
          }}
          rowClassName={(camera: TCameraBinding, index) => {
            const isThermalOpticalDevice =
              !isDeviceSegmented &&
              isOpticalCameraInThermalOpticalDevice({
                deviceTypeName: camera.deviceTypeName,
                cameraType: camera.cameraType
              });
            return `${styles.selectionColumn} ${
              isThermalOpticalDevice ? styles.thermalOpticalDevice : ''
            }${index % 2 ? ' row-even' : ' row-odd'} ${
              checkGroupIsNew(camera.serialNumber) ? 'new-group' : ''
            }`;
          }}
          pagination={false}
          loading={listCameraBinding.loading}
        />
      </SortableContext>
    </DndContext>
  );
}

export default memo(TableCameraBinding);
