import { usePermissionContext } from 'context/PermissionContext';
import { EFeatureKey, EStorageKey } from 'enums';
import { Navigate } from 'react-router-dom';
import { parseLocalUser } from 'utils/common';

function HomePageView() {
  const { permissionArray } = usePermissionContext();

  if (permissionArray.length > 0) {
    const oldUserDataFromLocal = parseLocalUser(
      sessionStorage.getItem(EStorageKey.EOSS_CURRENT_USER)
    );
    const oldUsername = oldUserDataFromLocal?.username || null;
    const newUserDataFromLocal = parseLocalUser(
      localStorage.getItem(EStorageKey.EOSS_CURRENT_USER)
    );
    const newUsername = newUserDataFromLocal?.username || undefined;
    const redirectTo = sessionStorage.getItem(EStorageKey.REDIRECT_TO);

    if (oldUsername === newUsername && redirectTo) {
      sessionStorage.removeItem(EStorageKey.EOSS_CURRENT_USER);
      sessionStorage.removeItem(EStorageKey.REDIRECT_TO);
      sessionStorage.removeItem(EStorageKey.FREEZING_ERROR);

      return <Navigate to={redirectTo} />;
    }

    if (permissionArray.includes(EFeatureKey.CAMERA_GROUP)) {
      return <Navigate to={EFeatureKey.CAMERA_GROUP.replace('_', '-')} />;
    }

    return <Navigate to={permissionArray[0]} />;
  }

  return (
    <div className="animate__animated animate__fadeIn h-100 d-flex align-items-center justify-content-center" />
  );
}

export default HomePageView;
