import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { Form } from 'antd';

import {
  DATETIME_FORMAT,
  DEFAULT_PAGE_NUMBER,
  INTERVAL_TIME_REFRESH_DEVICE_GROUP_BINDING,
  LIVE_ONLY_SERVICE_SUFFIX
} from 'constant';
import {
  EDeviceType,
  EHttpStatusCode,
  EServerType,
  ECameraType,
  Routes,
  ERoleKey,
  ESubFeatureKey
} from 'enums';

import { useEOSSContext } from 'context/EOSSContext';
import { useLoaderContext } from 'context/LoaderContext';
import { useAppUtil } from 'context/UtilContext';

import { useEffectOnce } from 'hooks/useEffectOnce';

import type { TAccount, TCamera, TCameraGroupAssign, TDevice, TServer } from 'models';
import { ApiErrorResponse } from 'models/ApiError';

import { DeviceRepository } from 'repositories';

import { AxiosClient } from 'services/axios';

import {
  checkDeviceStatus,
  convertToValidString,
  getDeviceGroupBinding,
  handleApiError,
  isOverlayCamera,
  isValidValue,
  mapToServerType
} from 'utils/common';
import useInterval from 'hooks/useInterval';
import { useAsyncState } from 'hooks/useAsyncState';
import { usePermission } from 'hooks/usePermission';

export type FormInformationFieldType = {
  account: {
    label: string;
    value: number;
  };
  formFactor: string;
  usageStartAt: string;
  bvrServerPrimary: number;
  bvrServerSecondary: number;
  bvrServerTertiary: number;
  localIPAddress: string;
  serialNumber: string;
  mac: string;
  soc: string;
  poe: string;
  modelData: string;
  opticalService: number;
  thermalService: number;
  thermalAnalyticService: number;
  overlayCameraService: number;
  opticalName: string;
  thermalName: string;
  opticalLens: string;
  thermalLens: string;
  location: string;
  mountLocation: string;
  comment: string;
  lineVolt: number;
  batteryVolt: number;
  load: number;
  batteryCharge: number;
  batteryDate: string;
  AIModelNameVersion: string; // TODO: Fill it when BE implemented,
  attachedCamera: string; // TODO: Fill it when BE implemented,
};

export type FormSystemRuntimeStatsFieldType = {
  cpuUtil: string;
  sdCardUtil: string;
  kernel: string;
  memUtil: string;
  cpuTemp: string;
};

export type FormBARCStatsFieldType = {
  status: string;
  curThrpt: string;
  maxThrpt: string;
  minThrpt: string;
  bufferSz: string;
  barcFrame: string;
};

export type FormUPSStatsFieldType = {
  lineVolt: number;
  batteryVolt: number;
  load: number;
  batteryCharge: number;
  batteryDate: string;
};

interface DataTypeService {
  id: number;
  code?: string;
  key?: React.ReactNode;
  children?: DataTypeService[];
  name: string;
}

export const useDeviceDetailPageController = () => {
  // Hooks and context-related declarations
  const { t } = useTranslation();
  const { loader, isActive } = useLoaderContext();
  const {
    listServices,
    listServerType,
    listDeviceType,
    refetchListServices,
    refetchListServerType
  } = useEOSSContext();
  const {
    listAccountsService,
    bindGroupAction,
    unAssignAction,
    listAccountsValid,
    scrollToTop,
    onSetCurrentDevice,
    onResetReplaceActionLoading,
    onIntervalActionTriggerChange
  } = useOutletContext<{
    bindGroupAction: {
      isProcessing: boolean;
      isSuccess: boolean;
      isLoading: boolean;
      payload?: AnyObject;
    };
    unAssignAction: { isProcessing: boolean; isSuccess: boolean };
    listAccountsService: TAccount[];
    listAccountsValid: TAccount[];
    onSetCurrentDevice: (device: TDevice | null) => void;
    scrollToTop: () => void;
    onResetReplaceActionLoading: () => void;
    onIntervalActionTriggerChange: (data: { payload: number[]; value: boolean }) => void;
  }>();

  const currentDeviceLoader = useLoaderData() as TDevice;
  const [formInformation] = Form.useForm<FormInformationFieldType>();

  // Utility hooks
  const { openNotification, openModal } = useAppUtil();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // State management
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isInEdit, setIsInEdit] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState<string>('');
  const [otherDeviceGroupsAssignment, setOtherDeviceGroupAssignment] = useAsyncState<
    TCameraGroupAssign[]
  >([]);
  const [listServicePackage, setListServicePackage] = useState<DataTypeService[]>([]);
  const [listServerBVR, setListServerBVR] = useState<TServer[]>([]);
  const [connectedBVR, setConnectedBVR] = useState<number[]>([]);
  const [camerasSelectedRow, setCamerasSelectedRow] = useState<React.Key[]>(() =>
    getDeviceGroupBinding(currentDeviceLoader).map((camera) => camera.id)
  );
  const [listAssignedCameraGroups, setListAssignedCameraGroups] = useAsyncState<
    Array<{
      id: number;
      name: string;
      cameraNames: string[];
    }>
  >(() => getDeviceGroupBinding(currentDeviceLoader));
  const [listIdsUnassign, setListIdsUnassign] = useState<number[]>([]);
  const [currentDevice, setCurrentDevice] = useState<TDevice>(currentDeviceLoader);
  const [intervalLoading, setIntervalLoading] = useState<boolean>(false);

  // Device type checks
  const isIPCamera = currentDevice?.typeName === EDeviceType.IP_CAMERA;
  const isAstro = currentDevice?.typeName === EDeviceType.ASTRO_CAMERA;
  const isThermalCamera = currentDevice?.typeName === EDeviceType.THERMAL_CAMERA;
  const isThermalOpticalCamera = currentDevice?.typeName === EDeviceType.THERMAL_OPTICAL_CAMERA;
  const haveOverlayCamera = currentDevice?.cameras.some((camera) =>
    isOverlayCamera(camera.cameraTypeName)
  );
  const isUPSMonitor = currentDevice?.typeName === EDeviceType.UPS_MONITOR;

  const hasDeviceUpdateServicePermisison = usePermission(
    ERoleKey.UPDATE,
    ESubFeatureKey.SERVICE
  ).allowed;

  // Repository instance creation
  const deviceRepository = DeviceRepository(AxiosClient);

  function removeEmptyChildren(nodes: DataTypeService[]): DataTypeService[] {
    return nodes.map(({ children, ...rest }: DataTypeService) => ({
      ...rest,
      ...(children && children.length > 0 ? { children: removeEmptyChildren(children) } : {})
    }));
  }

  const transformData = (data: DataTypeService[]): DataTypeService[] => {
    const nodeMap: Record<string, DataTypeService> = {};
    const rootNodes: DataTypeService[] = [];

    data.forEach((node) => {
      const parts = node.name.split('::');
      const nodeName = parts.pop() || '';
      const parentKey = parts.join('::');

      const newNode: DataTypeService = {
        ...node,
        name: nodeName,
        key: node.name.toLowerCase(),
        children: []
      };

      if (parentKey && nodeMap[parentKey]) {
        nodeMap[parentKey].children?.push(newNode);
      } else {
        rootNodes.push(newNode);
      }

      nodeMap[node.name] = newNode;
    });

    return removeEmptyChildren(rootNodes);
  };

  async function handleRefetchCameraGroupBinding() {
    try {
      if (!currentDevice) return;
      const { data, code } = await deviceRepository.getDeviceById({ deviceId: currentDevice.id });

      if (code === EHttpStatusCode.OK && data && data?.cameras && data.cameras.length > 0) {
        const groupData = getDeviceGroupBinding(data);
        setListAssignedCameraGroups((prev) => ({
          ...prev,
          data: groupData
        }));
        setCamerasSelectedRow(groupData.map((cameraGroup) => cameraGroup.id));
      }
      // Scroll to Camera Group Binding when Bind Camera Group to Device Success
    } catch (error) {
      const message = handleApiError({
        entity: t('devicePage.entity'),
        action: 'get',
        t,
        apiErrorResponse: error as ApiErrorResponse,
        identifier: currentDevice.id.toString()
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('devicePage.entity')}`,
        description: message
      });
    }
  }

  async function handleFetchOtherDeviceGroupsAssignment(location: string) {
    try {
      if (!currentDevice) return;

      setOtherDeviceGroupAssignment({
        data: [],
        loading: true
      });

      const data = await deviceRepository.getListDeviceGroupByLocation({
        page: 0,
        location: location,
        accountId: currentDevice.account.id
      });

      setOtherDeviceGroupAssignment({
        data: data.data,
        loading: false
      });
    } catch (error) {
      setOtherDeviceGroupAssignment({
        data: [],
        loading: false
      });
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('devicePage.sections.bindCameraGroup.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('devicePage.sections.bindCameraGroup.entity')}`,
        description: message
      });
    }
  }

  async function handleRefetchDeviceAccount() {
    try {
      if (!currentDevice) return;
      const { data, code } = await deviceRepository.getDeviceById({ deviceId: currentDevice.id });
      if (code === EHttpStatusCode.OK && data) {
        setCurrentDevice((prev) => ({
          ...prev,
          account: data.account,
          cameras: prev.cameras.map((val) => {
            const findCamera = data.cameras.find((camera) => camera?.id === val?.id);
            return {
              ...val,
              service: findCamera ? findCamera.service : null,
              thermogService: findCamera ? findCamera.thermogService : null,
              groups: findCamera ? findCamera.groups : []
            };
          }),
          servers: data.servers
        }));
        setCamerasSelectedRow([]);
        setListAssignedCameraGroups({
          data: [],
          loading: false
        });
        formInformation.setFieldsValue({
          account: {
            label: data.account.name,
            value: data.account.id
          },
          opticalService: -1,
          thermalService: -1,
          thermalAnalyticService: -1,
          bvrServerPrimary: -1,
          bvrServerSecondary: -1,
          bvrServerTertiary: -1
        });
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('devicePage.entity'),
        t,
        identifier: currentDevice.id.toString()
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('devicePage.entity')}`,
        description: message
      });
    }
  }

  async function handleUpdateDeviceBasic({ currentDevice }: { currentDevice: TDevice }) {
    try {
      const locationField = formInformation.getFieldValue('location');
      const mountLocationField = formInformation.getFieldValue('mountLocation');
      const commentField = formInformation.getFieldValue('comment');
      const formFactorField = formInformation.getFieldValue('formFactor');

      const currentLocation = currentDevice.location ?? '';
      const currentMountLocation = currentDevice.mountLocation ?? '';

      const currentComment = currentDevice.comment ?? '';
      const currentFormFactor = currentDevice.formFactor ?? '';

      const isLocationChanged = locationField !== currentLocation;
      const isMountLocationChanged = mountLocationField !== currentMountLocation;
      const isCommentChanged = commentField !== currentComment;
      const isFormFactorChanged = formFactorField !== currentFormFactor;

      const isChanged =
        isLocationChanged || isCommentChanged || isFormFactorChanged || isMountLocationChanged;

      if (isChanged) {
        return await deviceRepository.updateDeviceBasic({
          deviceId: currentDevice.id,
          comment: commentField.trim(),
          location: locationField.trim(),
          mountLocation: mountLocationField.trim(),
          form: formFactorField.trim()
        });
      }
      return EHttpStatusCode.NOT_MODIFIED;
    } catch (error) {
      const err = error as ApiErrorResponse;
      const message = handleApiError({
        apiErrorResponse: err,
        action: 'update',
        entity: t('devicePage.entity'),
        t,
        identifier: currentDevice.serialNumber
      });
      openNotification({
        type: 'error',
        title: `${t('actions.update')} ${t('devicePage.entity')}`,
        description: message
      });
      return err.status;
    }
  }

  async function handleUpdateDeviceAccount({ currentDevice }: { currentDevice: TDevice }) {
    try {
      const accountField = formInformation.getFieldValue('account') as {
        label: string;
        value: number;
      };
      const isAccountChanged = accountField.value !== currentDevice.account.id;

      if (isAccountChanged) {
        return await deviceRepository.updateDeviceAccount({
          deviceId: currentDevice.id,
          accountId: accountField.value
        });
      }
      return EHttpStatusCode.NOT_MODIFIED;
    } catch (error) {
      const err = error as ApiErrorResponse;
      const message = handleApiError({
        apiErrorResponse: err,
        action: 'update',
        entity: t('devicePage.entity'),
        t,
        identifier: currentDevice.serialNumber
      });
      openNotification({
        type: 'error',
        title: `${t('actions.update')} ${t('devicePage.entity')}`,
        description: message
      });
      return err.status;
    }
  }

  async function handleUpdateDeviceServers({ currentDevice }: { currentDevice: TDevice }) {
    try {
      const bvrServerPrimaryField = formInformation.getFieldValue('bvrServerPrimary');
      const bvrServerSecondaryField = formInformation.getFieldValue('bvrServerSecondary');
      const bvrServerTertiaryField = formInformation.getFieldValue('bvrServerTertiary');

      const currentServerPrimary = currentDevice.servers[0]?.id ?? -1;
      const currentServerSecondary = currentDevice.servers[1]?.id ?? -1;
      const currentServerTertiary = currentDevice.servers[2]?.id ?? -1;

      const isBvrServerPrimaryChanged = bvrServerPrimaryField !== currentServerPrimary;

      const isBvrServerSecondaryChanged = bvrServerSecondaryField !== currentServerSecondary;

      const isBvrServerTertiaryChanged = bvrServerTertiaryField !== currentServerTertiary;

      const isChanged =
        isBvrServerPrimaryChanged || isBvrServerSecondaryChanged || isBvrServerTertiaryChanged;

      if (isChanged) {
        return await deviceRepository.updateDeviceServers({
          deviceId: currentDevice.id,
          serverIds: [bvrServerPrimaryField, bvrServerSecondaryField, bvrServerTertiaryField]
            .filter((id) => id !== -1)
            .toString()
        });
      }
      return EHttpStatusCode.NOT_MODIFIED;
    } catch (error) {
      const err = error as ApiErrorResponse;
      const message = handleApiError({
        apiErrorResponse: err,
        action: 'update',
        entity: t('devicePage.entity'),
        t,
        identifier: currentDevice.serialNumber
      });
      openNotification({
        type: 'error',
        title: `${t('actions.update')} ${t('devicePage.entity')}`,
        description: message
      });
      return err.status;
    }
  }

  async function handleUpdateDeviceCamera({
    currentDevice
  }: {
    currentDevice: TDevice;
  }): Promise<PromiseSettledResult<EHttpStatusCode>[]> {
    const res = await Promise.allSettled(
      currentDevice.cameras.map(
        async ({
          id,
          cameraTypeName = '',
          lens = '',
          name = '',
          service,
          thermogService
        }: TCamera) => {
          try {
            const isOptical = cameraTypeName.toLowerCase() === ECameraType.OPTICAL;
            const isOverlay = cameraTypeName.toLowerCase() === ECameraType.OVERLAY;
            const isThermalCamera = cameraTypeName.toLowerCase() === ECameraType.THERMAL;

            const lensField = formInformation.getFieldValue(
              isOptical ? 'opticalLens' : 'thermalLens'
            );
            const cameraNameField = formInformation.getFieldValue(
              isOptical ? 'opticalName' : 'thermalName'
            );
            const serviceField = formInformation.getFieldValue(
              isOptical ? 'opticalService' : isOverlay ? 'overlayCameraService' : 'thermalService'
            );
            const thermalAnalyticServiceField = !isOptical
              ? formInformation.getFieldValue('thermalAnalyticService')
              : -1;

            const currentService = service ? service.id : -1;
            const currentThermalAnalyticService = thermogService ? thermogService.id : -1;

            const isLensChanged = lensField !== lens;
            const isCameraNameChanged = cameraNameField !== name;
            const isServiceChanged = serviceField !== currentService;
            const isThermalServiceChanged =
              thermalAnalyticServiceField !== currentThermalAnalyticService;

            let isChanged = isServiceChanged;

            // Overlay camera doesn't have name and lens field
            if (!isOverlay) {
              isChanged = isChanged || isCameraNameChanged || isLensChanged;
            }

            // Check thermal service only when it's thermal camera
            if (isThermalCamera) {
              isChanged = isChanged || isThermalServiceChanged;
            }

            if (isChanged) {
              return await deviceRepository.updateCamera({
                cameraId: id,
                lens: isOverlay ? lens : lensField.trim(),
                name: isOverlay ? name : cameraNameField.trim(),
                serviceId: serviceField !== -1 ? serviceField : null,
                thermogServiceId:
                  thermalAnalyticServiceField !== -1 && !isOverlay
                    ? thermalAnalyticServiceField
                    : null
              });
            }
            return EHttpStatusCode.NOT_MODIFIED;
          } catch (error) {
            const err = error as ApiErrorResponse;
            const message = handleApiError({
              apiErrorResponse: err,
              action: 'update',
              entity: t('devicePage.cameraEntity'),
              t,
              identifier: currentDevice.serialNumber
            });
            openNotification({
              type: 'error',
              title: `${t('actions.update')} ${t('devicePage.cameraEntity')}`,
              description: message
            });
            return err.status;
          }
        }
      )
    );

    return res;
  }

  async function handleUnbindCameraGroup({ currentDevice }: { currentDevice: TDevice }) {
    try {
      const isChanged = listIdsUnassign.length > 0;
      if (isChanged) {
        return await deviceRepository.unbindCameraGroups({
          deviceId: currentDevice.id,
          cameraGroupIds: listIdsUnassign.toString()
        });
      }
      return EHttpStatusCode.NOT_MODIFIED;
    } catch (error) {
      const err = error as ApiErrorResponse;
      const message = handleApiError({
        apiErrorResponse: err,
        action: 'other',
        entity: t('devicePage.sections.bindCameraGroup.entity'),
        t,
        identifier: currentDevice.serialNumber
      });
      openNotification({
        type: 'error',
        title: `${t('actions.bind')} ${t('devicePage.sections.bindCameraGroup.entity')}`,
        description: message
      });
      handleRefetchCameraGroupBinding();
      return err.status;
    }
  }

  const isLiveOnlyServiceChange = (
    serviceId: number,
    listServicePackage: DataTypeService[]
  ): boolean => {
    const flatService = (
      listServicePackage: DataTypeService[]
    ): Array<{ id: number; code?: string }> => {
      return listServicePackage.reduce(
        (acc: Array<{ id: number; code?: string }>, curr: DataTypeService) => {
          const data = [...acc, { id: curr.id, code: curr.code }];
          if (curr.children && curr.children.length > 0) {
            return [...data, ...flatService(curr.children)];
          }
          return data;
        },
        []
      );
    };

    const flatServiceList = flatService(listServicePackage);

    const code = flatServiceList.find((service) => service.id === serviceId)?.code;

    // Code exist && code is live only (end with triple zero ('000'))
    return !!code && code.endsWith(LIVE_ONLY_SERVICE_SUFFIX);
  };

  async function handleSubmit() {
    if (!currentDevice || isSubmitting) return;
    setIsSubmitting(true);

    const isServiceSetToLiveOnly = isLiveOnlyServiceChange(
      formInformation.getFieldValue('opticalService'),
      listServicePackage
    );

    const isChangeAnyBVRServer =
      formInformation.getFieldValue('bvrServerPrimary') !== (currentDevice?.servers[0]?.id ?? -1) ||
      formInformation.getFieldValue('bvrServerSecondary') !==
        (currentDevice?.servers[1]?.id ?? -1) ||
      formInformation.getFieldValue('bvrServerTertiary') !== (currentDevice?.servers[2]?.id ?? -1);

    const additionalMessage =
      isServiceSetToLiveOnly && isChangeAnyBVRServer
        ? t('devicePage.modal.bothChange')
        : isServiceSetToLiveOnly
        ? t('devicePage.modal.setLiveOnly')
        : isChangeAnyBVRServer
        ? t('devicePage.modal.changeBVR')
        : '';

    openModal({
      title: t('components.confirmationTitle'),
      content: `${additionalMessage} ${t('devicePage.modal.action', {
        action: t('actions.update').toLowerCase(),
        entity: t('header.devices').toLowerCase()
      })}`,
      okText: t('components.ok'),
      cancelText: t('components.cancel'),
      onOk: async () => {
        const [
          resUpdateDeviceBasic,
          resUpdateDeviceAccount,
          resUpdateDeviceServer,
          resUpdateDeviceCamera,
          resUnbindCameraGroup
        ] = await Promise.allSettled([
          handleUpdateDeviceBasic({ currentDevice }),
          handleUpdateDeviceAccount({ currentDevice }),
          handleUpdateDeviceServers({ currentDevice }),
          handleUpdateDeviceCamera({ currentDevice }),
          handleUnbindCameraGroup({ currentDevice })
        ]);
        const isUpdateDeviceBasicFailed =
          resUpdateDeviceBasic.status === 'fulfilled' &&
          resUpdateDeviceBasic.value !== EHttpStatusCode.OK &&
          resUpdateDeviceBasic.value !== EHttpStatusCode.NOT_MODIFIED;
        const isDeviceBasicUnChanged =
          resUpdateDeviceBasic.status === 'fulfilled' &&
          resUpdateDeviceBasic.value === EHttpStatusCode.NOT_MODIFIED;

        const isUpdateDeviceAccountFailed =
          resUpdateDeviceAccount.status === 'fulfilled' &&
          resUpdateDeviceAccount.value !== EHttpStatusCode.OK &&
          resUpdateDeviceAccount.value !== EHttpStatusCode.NOT_MODIFIED;
        const isDeviceAccountUnChanged =
          resUpdateDeviceAccount.status === 'fulfilled' &&
          resUpdateDeviceAccount.value === EHttpStatusCode.NOT_MODIFIED;

        const isUpdateDeviceServerFailed =
          resUpdateDeviceServer.status === 'fulfilled' &&
          resUpdateDeviceServer.value !== EHttpStatusCode.OK &&
          resUpdateDeviceServer.value !== EHttpStatusCode.NOT_MODIFIED;
        const isDeviceServerUnChanged =
          resUpdateDeviceServer.status === 'fulfilled' &&
          resUpdateDeviceServer.value === EHttpStatusCode.NOT_MODIFIED;

        const isUpdateDeviceCameraFailed =
          resUpdateDeviceCamera.status === 'fulfilled' &&
          resUpdateDeviceCamera.value.filter(
            (status) =>
              status.status === 'fulfilled' &&
              status.value !== EHttpStatusCode.OK &&
              status.value !== EHttpStatusCode.NOT_MODIFIED
          ).length > 0;
        const isDeviceCameraUnChanged =
          resUpdateDeviceCamera.status === 'fulfilled' &&
          (resUpdateDeviceCamera.value.length <= 0 ||
            resUpdateDeviceCamera.value.filter(
              (status) =>
                status.status === 'fulfilled' && status.value === EHttpStatusCode.NOT_MODIFIED
            ).length > 0);

        const isUnbindCameraGroupFailed =
          resUnbindCameraGroup.status === 'fulfilled' &&
          resUnbindCameraGroup.value !== EHttpStatusCode.OK &&
          resUnbindCameraGroup.value !== EHttpStatusCode.NOT_MODIFIED;
        const isCameraGroupUnChanged =
          resUnbindCameraGroup.status === 'fulfilled' &&
          resUnbindCameraGroup.value === EHttpStatusCode.NOT_MODIFIED;

        const isDeviceUnChanged =
          isDeviceBasicUnChanged &&
          isDeviceAccountUnChanged &&
          isDeviceServerUnChanged &&
          isDeviceCameraUnChanged &&
          isCameraGroupUnChanged;

        const isHasFailed =
          isUpdateDeviceAccountFailed ||
          isUpdateDeviceBasicFailed ||
          isUpdateDeviceCameraFailed ||
          isUpdateDeviceServerFailed ||
          isUnbindCameraGroupFailed;

        const isSuccessfully = !isHasFailed;

        if (isSuccessfully) {
          openNotification({
            type: 'success',
            title: `${t('actions.update')} ${t('devicePage.entity')}`,
            description: `${t('components.success')}!`
          });
        }

        if (isDeviceUnChanged || isSuccessfully) {
          if (!searchParams.get('page')) {
            searchParams.set('page', DEFAULT_PAGE_NUMBER.toString());
            setSearchParams(searchParams);
          }
          navigate({
            pathname: `${Routes.EDevicesRoutes.INDEX}/${(
              currentDevice.typeName ?? ''
            ).toLowerCase()}`,
            search: searchParams.toString()
          });
        }
        setIsSubmitting(false);
      },
      onCancel: () => {
        setIsSubmitting(false);
      }
    });
  }

  async function fetchListServer() {
    try {
      const bvrServer = listServerType.find(
        (server) => mapToServerType(server?.name) === EServerType.BVR
      );
      if (!bvrServer) return;
      const res = await deviceRepository.getListServer({ typeId: bvrServer?.id });
      setListServerBVR(res);
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('serverPage.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('serverPage.entity')}`,
        description: message
      });
    }
  }

  const handleListIdsUnassignChange = (value: number[]) => {
    setListIdsUnassign(value);
  };

  const checkIsRecording = ({ device, type }: { device: TDevice; type: ECameraType }): boolean => {
    if (device && device?.lastContactAt) {
      const camera = device.cameras.find((c) => c?.cameraTypeName?.toLowerCase() === type);

      if (camera) {
        const isDeviceOnline = checkDeviceStatus(camera?.lastContact);
        const isBVRConnected = device?.bvrConnected ? device.bvrConnected.length > 0 : false;
        return isDeviceOnline && isBVRConnected;
      }
    }
    return false;
  };

  const checkIsOnline = ({ device, type }: { device: TDevice; type: ECameraType }): boolean => {
    if (device && device?.lastContactAt) {
      const camera = device.cameras.find((c) => c?.cameraTypeName?.toLowerCase() === type);
      if (camera) {
        return checkDeviceStatus(camera?.lastContact);
      }
    }
    return false;
  };

  const handleCamerasGroupBindingSelectedChange = (keys: React.Key[]) => {
    setCamerasSelectedRow(keys);
    setIsInEdit(true);
  };

  async function handleIntervalRefreshCameraBindingData() {
    if (currentDevice?.id === null || currentDevice?.id === undefined) return;
    try {
      setListAssignedCameraGroups((prev) => ({
        ...prev,
        loading: !isInEdit
      }));
      setOtherDeviceGroupAssignment((prev) => ({
        ...prev,
        loading: true
      }));
      setIntervalLoading(true);
      const { data, code } = await deviceRepository.getDeviceById({ deviceId: currentDevice.id });
      if (code === EHttpStatusCode.OK) {
        setCurrentDevice((prev) => ({
          ...prev,
          data: data.data,
          systemData: data.systemData,
          kernel: data.kernel
        }));
        if (!isInEdit) {
          if (isAstro || isUPSMonitor) {
            // New data has location
            if (data?.location && data.location.length > 0) {
              const newData = await deviceRepository.getListDeviceGroupByLocation({
                page: 0,
                location: data.location,
                accountId: currentDevice.account.id
              });

              setOtherDeviceGroupAssignment((prev) => ({
                ...prev,
                data: newData.data
              }));
            } else {
              // Set to empty array when location is null or empty
              setOtherDeviceGroupAssignment((prev) => ({
                ...prev,
                data: []
              }));
            }
          } else {
            setListAssignedCameraGroups((prev) => ({
              ...prev,
              data: getDeviceGroupBinding(data)
            }));

            setCamerasSelectedRow(getDeviceGroupBinding(data).map((camera) => camera.id));
            onIntervalActionTriggerChange({
              value: true,
              payload: getDeviceGroupBinding(data).map(({ id }) => id)
            });

            if (getDeviceGroupBinding(data).length !== camerasSelectedRow.length) {
              setWarningMessage(t('cameraGroupPage.sections.deviceBind.warning'));
              setTimeout(() => {
                setWarningMessage('');
              }, 4000);
            }
          }
        }
      }

      if (isUPSMonitor || isAstro) {
        if (data.location) {
          await handleFetchOtherDeviceGroupsAssignment(data.location);
        }
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('serverPage.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('serverPage.entity')}`,
        description: message
      });
    } finally {
      setListAssignedCameraGroups((prev) => ({
        ...prev,
        loading: false
      }));
      setOtherDeviceGroupAssignment((prev) => ({
        ...prev,
        loading: false
      }));
      setIntervalLoading(false);
    }
  }

  useEffect(() => {
    refetchListServices();
    refetchListServerType();
    scrollToTop();
  }, []);

  useInterval(
    () => {
      handleIntervalRefreshCameraBindingData();
    },
    INTERVAL_TIME_REFRESH_DEVICE_GROUP_BINDING,
    true
  );

  useEffect(() => {
    if (bindGroupAction.isSuccess) {
      if (bindGroupAction.payload) {
        handleFetchOtherDeviceGroupsAssignment(bindGroupAction.payload.deviceGroupName).then(
          () => {}
        );
        formInformation.setFieldValue('location', bindGroupAction.payload.deviceGroupName);
      }
      handleRefetchCameraGroupBinding();
    }
  }, [bindGroupAction.isSuccess, bindGroupAction.payload]);

  useEffect(() => {
    if (unAssignAction.isSuccess) {
      handleRefetchDeviceAccount();
      // Clear list assigned camera group binding when change device customer owner
      setListAssignedCameraGroups({
        data: [],
        loading: false
      });
    }
  }, [unAssignAction.isSuccess]);

  useEffect(() => {
    setListServicePackage(transformData(listServices));
  }, [listServices]);

  useEffectOnce(() => {
    if (currentDevice) {
      onSetCurrentDevice(currentDevice);
    }
  }, [currentDevice]);

  useEffect(() => {
    if (listServerType.length > 0) {
      fetchListServer();
    }
  }, [listServerType]);

  const getModelData = () => {
    const getModelDataFromCamera = (camera: TCamera) => {
      const properties = [
        camera?.typeName || '-',
        camera?.productSerial || '-',
        camera?.firmware || '-'
      ];

      return properties.some((value) => value !== '-') ? properties.join('_') : '-';
    };

    try {
      const deviceCameras = currentDevice?.cameras || [];

      if (currentDevice?.typeName === EDeviceType.THERMAL_OPTICAL_CAMERA) {
        const modelResult = deviceCameras
          .filter((c) =>
            [ECameraType.OPTICAL.toString(), ECameraType.THERMAL.toString()].includes(
              c?.cameraTypeName
            )
          )
          .map(getModelDataFromCamera);

        return modelResult.length > 0 ? modelResult.join('\n') : '-';
      } else {
        return deviceCameras.length > 0 ? getModelDataFromCamera(deviceCameras[0]) : '-';
      }
    } catch (error) {
      return '-';
    }
  };

  useEffect(() => {
    if (currentDeviceLoader && currentDeviceLoader?.cameras) {
      const opticalCamera = currentDeviceLoader.cameras.find(
        ({ cameraTypeName = '' }: TCamera) => cameraTypeName.toLowerCase() === ECameraType.OPTICAL
      );
      const thermalCamera = currentDeviceLoader.cameras.find(
        ({ cameraTypeName = '' }: TCamera) => cameraTypeName.toLowerCase() === ECameraType.THERMAL
      );

      // Input field
      formInformation.setFieldsValue({
        modelData: isAstro
          ? convertToValidString({
              value: currentDeviceLoader?.soc
            })
          : getModelData(),
        serialNumber: convertToValidString({
          value: currentDeviceLoader?.serialNumber
        }),
        localIPAddress:
          currentDeviceLoader?.localIp ||
          currentDeviceLoader?.publicIp ||
          currentDeviceLoader?.serviceProvider
            ? `${convertToValidString({
                value: currentDeviceLoader?.localIp
              })}/${convertToValidString({
                value: currentDeviceLoader?.publicIp
              })}/${convertToValidString({
                value: currentDeviceLoader?.serviceProvider
              })}`
            : '-',
        soc:
          currentDeviceLoader?.soc || currentDeviceLoader?.ram
            ? `${convertToValidString({
                value: currentDeviceLoader?.soc
              })}/${convertToValidString({
                value: currentDeviceLoader?.ram
              })}`
            : '-',
        poe: convertToValidString({
          value: currentDeviceLoader?.poe
        }),
        mac: convertToValidString({
          value: currentDeviceLoader?.mac
        }),
        usageStartAt: currentDeviceLoader?.usageStartAt
          ? dayjs.unix(currentDeviceLoader.usageStartAt).format(DATETIME_FORMAT)
          : '-',
        formFactor: convertToValidString({
          value: currentDeviceLoader?.formFactor,
          defaultValue: ''
        }),
        location: convertToValidString({
          value: currentDeviceLoader?.location,
          defaultValue: ''
        }),
        mountLocation: convertToValidString({
          value: currentDeviceLoader?.mountLocation,
          defaultValue: ''
        }),
        comment: convertToValidString({
          value: currentDeviceLoader?.comment,
          defaultValue: ''
        }),
        // AIModelNameVersion: '-', ERRP-212: Hide this field, Request by Josh 2024-Sep-06,
        attachedCamera: convertToValidString({
          value: currentDeviceLoader?.astroCamera
        }),
        lineVolt: currentDeviceLoader?.data?.lineVolt,
        batteryVolt: currentDeviceLoader?.data?.batteryVolt,
        load: currentDeviceLoader?.data?.load,
        batteryCharge: currentDeviceLoader?.data?.batteryCharge,
        batteryDate: currentDeviceLoader?.data?.batteryDate,
        opticalName: convertToValidString({
          value: opticalCamera?.name,
          defaultValue: ''
        }),
        thermalName: convertToValidString({
          value: thermalCamera?.name,
          defaultValue: ''
        }),
        opticalLens: convertToValidString({
          value: opticalCamera?.lens,
          defaultValue: ''
        }),
        thermalLens: convertToValidString({
          value: thermalCamera?.lens,
          defaultValue: ''
        })
      });
    }
  }, [currentDeviceLoader, isAstro]);

  useEffect(() => {
    if ((isAstro || isUPSMonitor) && currentDevice?.location?.trim()) {
      handleFetchOtherDeviceGroupsAssignment(currentDevice.location.trim());
    }
  }, [currentDevice.location, isAstro, isUPSMonitor]);

  useEffect(() => {
    if (listAccountsService.length > 0) {
      if (hasDeviceUpdateServicePermisison) {
        formInformation.setFieldsValue({
          account: {
            label: currentDeviceLoader?.account?.name,
            value: currentDeviceLoader?.account?.id
          }
        });
      } else {
        formInformation.setFieldValue('account', currentDeviceLoader?.account?.name);
      }
    }
  }, [listAccountsService, currentDeviceLoader?.account, hasDeviceUpdateServicePermisison]);

  useEffect(() => {
    if (currentDeviceLoader?.cameras && listServicePackage.length > 0) {
      const findCameraByType = (type: string) =>
        currentDeviceLoader.cameras.find(
          ({ cameraTypeName = '' }: TCamera) => cameraTypeName.toLowerCase() === type
        );
      const opticalCamera = findCameraByType(ECameraType.OPTICAL);
      const thermalCamera = findCameraByType(ECameraType.THERMAL);
      const overlayCamera = findCameraByType(ECameraType.OVERLAY);

      formInformation.setFieldsValue({
        opticalService: opticalCamera?.service?.id ?? -1,
        thermalService: thermalCamera?.service?.id ?? -1,
        thermalAnalyticService: thermalCamera?.thermogService?.id ?? -1,
        overlayCameraService: overlayCamera?.service?.id ?? -1
      });
    }
  }, [currentDeviceLoader.cameras, listServicePackage]);

  useEffect(() => {
    if (listServerBVR.length > 0) {
      const serverIds = currentDeviceLoader?.servers?.map((server) => server.id) || [];
      const bvrConnectedId = serverIds.filter(isValidValue);

      formInformation.setFieldsValue({
        bvrServerPrimary: serverIds[0] ?? (hasDeviceUpdateServicePermisison ? -1 : '-'),
        bvrServerSecondary: serverIds[1] ?? (hasDeviceUpdateServicePermisison ? -1 : '-'),
        bvrServerTertiary: serverIds[2] ?? (hasDeviceUpdateServicePermisison ? -1 : '-')
      });
      setConnectedBVR(bvrConnectedId);
    }
  }, [listServerBVR, currentDeviceLoader.servers, hasDeviceUpdateServicePermisison]);

  useEffectOnce(() => {
    if (isActive) {
      loader.complete();
    }
  }, [isActive]);

  useEffect(() => {
    setCurrentDevice(currentDeviceLoader);
    setListAssignedCameraGroups({
      data: getDeviceGroupBinding(currentDeviceLoader),
      loading: false
    });
    onResetReplaceActionLoading();
  }, [currentDeviceLoader]);

  return {
    isInEdit,
    warningMessage,
    intervalLoading,
    isIPCamera,
    isAstro,
    isThermalCamera,
    isThermalOpticalCamera,
    isUPSMonitor,
    haveOverlayCamera,
    camerasSelectedRow,
    connectedBVR,
    isAccountInvalid:
      listAccountsValid.length > 0 &&
      currentDevice &&
      !listAccountsValid.find((account) => account?.id === currentDevice?.account?.id),
    bindGroupAction,
    currentDevice,
    listDeviceType,
    formInformation,
    listServicePackage,
    listServerBVR,
    isOpticalRecording: checkIsRecording({
      device: currentDevice,
      type: ECameraType.OPTICAL
    }),
    isThermalRecording: checkIsRecording({
      device: currentDevice,
      type: ECameraType.THERMAL
    }),
    isOpticalOnline: checkIsOnline({
      device: currentDevice,
      type: ECameraType.OPTICAL
    }),
    isThermalOnline: checkIsOnline({
      device: currentDevice,
      type: ECameraType.THERMAL
    }),
    listAssignedCameraGroups,
    listAccountsService,
    otherDeviceGroupsAssignment,
    hasDeviceUpdateServicePermisison,
    onSubmit: handleSubmit,
    onListIdsUnassignChange: handleListIdsUnassignChange,
    onCamerasGroupBindingSelectedChange: handleCamerasGroupBindingSelectedChange,
    onResetReplaceActionLoading,
    onInEditChange: () => {
      setIsInEdit((prev) => !prev);
    }
  };
};
