import { Form, Result } from 'antd';
import dayjs from 'dayjs';
import { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_BEGINNING_TIME, DATE_FORMAT, MIDNIGHT_TIME } from 'constant';
import { ERoleKey } from 'enums';
import { usePermission } from 'hooks/usePermission';
import type { TPutAccountsRequest } from 'repositories/Account/AccountRepository';
import type { TAccountFieldType } from '../AccountPage/AccountPageController';
import { Billing, Contacts, DevicesSummary, General } from '../AccountPage/components';
import { useAccountDetailController } from './AccountDetailController';
import { TContact } from 'models';
import { useOutletContext } from 'react-router-dom';
import { useAppUtil } from 'context/UtilContext';
import { useEOSSContext } from 'context/EOSSContext';
import { FormAction } from 'presentation/components';
import styles from './accountDetail.module.scss';
import { checkFormIsValid } from 'utils/common';

function AccountDetailPageView() {
  const { t } = useTranslation();

  const {
    refEl,
    deviceTypeSelected,
    form,
    billingForm,
    listValidation,
    serviceDeviceList,
    devicesSummaryList,
    deviceReferenceList,
    currentAccount,
    isAllowedUpdate,
    onUpdateAccount,
    onCancel,
    onDeviceTypeChange,
    onServiceDeviceChangeSort,
    onPageNumberChange
  } = useAccountDetailController();

  const { openModal } = useAppUtil();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { scrollToTop } = useOutletContext<{ scrollToTop: () => void }>();
  const { listDeviceType } = useEOSSContext();

  useLayoutEffect(() => scrollToTop(), []);

  const handleConfirmSubmit = (value: TAccountFieldType) => {
    const billingField = billingForm?.getFieldsValue(['billing']);
    const contactData = value.contacts
      ? value.contacts.reduce((acc: TContact[], value: TContact) => {
          if (
            (value.name && value.name.length > 0) ||
            (value.phone && value.phone.length > 0) ||
            (value.email && value.email.length > 0)
          ) {
            acc.push({
              name: value.name ? value.name.trim() : '',
              email: value.email ? value.email.trim() : '',
              phone: value.phone ? value.phone.trim() : ''
            });
          }
          return acc;
        }, [])
      : [];
    if (currentAccount) {
      const dataSubmit: TPutAccountsRequest = {
        address: {
          street: value.street?.trim() ?? '',
          city: value.city?.trim() ?? '',
          state: value.state?.trim() ?? '',
          zip: value.zip?.trim() ?? ''
        },
        url: value.url?.trim() ?? '',
        comment: value.comment?.trim() ?? '',
        accountTypeId: currentAccount.accountType.id,
        domainId: currentAccount.domain.id,
        invalid: value.invalidId,
        billingStartAt: billingField.billing?.start
          ? dayjs(
              dayjs(billingField.billing.start).format(DATE_FORMAT) + DATE_BEGINNING_TIME
            ).unix()
          : null,
        billingEndAt: billingField.billing?.stop
          ? dayjs(dayjs(billingField.billing.stop).format(DATE_FORMAT) + MIDNIGHT_TIME).unix()
          : null,
        discountRate: billingField.billing?.discount?.trim() ?? '',
        stateTax: billingField.billing?.stateTax?.trim() ?? '',
        localTax: billingField.billing?.localTax?.trim() ?? '',
        contact: contactData,
        id: currentAccount.id,
        language: ''
      };

      setIsSubmitting(true);
      openModal({
        title: t('components.confirmationTitle'),
        content: t('components.confirmationMessage', {
          action: t('actions.update').toLowerCase(),
          entity: t('header.account').toLowerCase()
        }),
        okText: `${t('components.ok')}`,
        cancelText: `${t('components.cancel')}`,
        onOk: async () => {
          await onUpdateAccount(dataSubmit);
          setIsSubmitting(false);
        },
        onCancel: () => setIsSubmitting(false)
      });
    }
  };

  if (!currentAccount) {
    return (
      <Result
        status="404"
        title={t('accountPage.404.title')}
        subTitle={t('accountPage.404.subTitle')}
      />
    );
  }

  return (
    <Form
      form={form}
      onFinish={(value: TAccountFieldType) => {
        /**
         * Check form billing has no error
         */
        if (checkFormIsValid(billingForm) && checkFormIsValid(form)) {
          handleConfirmSubmit(value);
        }
      }}
      className={`${styles.container} pe-2`}
      layout="horizontal"
      labelCol={{
        style: {
          width: 100
        }
      }}
      scrollToFirstError
      disabled={!isAllowedUpdate}
      colon={false}
    >
      <div className="row">
        <div className="col-12 col-lg-7 col-slg-8 col-md-9">
          <General
            currentData={currentAccount}
            validationsData={listValidation}
            disabled={!isAllowedUpdate}
          />
          <div className={styles.showInSmallDesktop}>
            <Contacts />
          </div>
        </div>
        <div className="col-12 col-lg-5 col-slg-4 col-md-3">
          <div className={styles.showInSmallDesktop}>
            <DevicesSummary
              data={
                // ERRP-52 Issue - No filter SIR200 from Device Summary List
                listDeviceType.map((deviceType) => {
                  const deviceSummaryFound = devicesSummaryList.data.find(
                    (deviceSummary) =>
                      (deviceSummary.typeName ?? '').toLowerCase() ===
                      (deviceType.name ?? '').toLowerCase()
                  );
                  return {
                    ...(deviceSummaryFound
                      ? deviceSummaryFound
                      : {
                          offline: 0,
                          online: 0,
                          total: 0,
                          typeAndDisplayName: deviceType.displayName,
                          typeName: deviceType.name
                        }),
                    typeAndDisplayName: [
                      deviceSummaryFound?.typeName ?? deviceType.name,
                      deviceType.displayName
                    ]
                  };
                }) as []
              }
              loading={devicesSummaryList.loading}
              accountName={currentAccount.name}
            />
          </div>
          <Billing form={billingForm} />

          <div ref={refEl} />
        </div>
      </div>
      <div className={styles.hideInSmallDesktop}>
        <Contacts />
      </div>
      <div className={styles.hideInSmallDesktop}>
        <DevicesSummary
          data={
            // ERRP-52 Issue - No filter SIR200 from Device Summary List
            listDeviceType.map((deviceType) => {
              const deviceSummaryFound = devicesSummaryList.data.find(
                (deviceSummary) =>
                  (deviceSummary.typeName ?? '').toLowerCase() ===
                  (deviceType.name ?? '').toLowerCase()
              );

              return {
                ...(deviceSummaryFound
                  ? deviceSummaryFound
                  : {
                      offline: 0,
                      online: 0,
                      total: 0,
                      typeAndDisplayName: deviceType.displayName,
                      typeName: deviceType.name
                    }),
                typeAndDisplayName: [
                  deviceSummaryFound?.typeName ?? deviceType.name,
                  deviceType.displayName
                ]
              };
            }) as []
          }
          loading={devicesSummaryList.loading}
          accountName={currentAccount.name}
        />
      </div>

      {/**
       * TODO: ERRP-135: Hide this action - request by Josh & Tom
       */}

      {/* <ServiceDevice
        listData={serviceDeviceList}
        currentDeviceType={deviceTypeSelected}
        listDeviceType={listDeviceType}
        onDeviceTypeChange={onDeviceTypeChange}
        onChangeSort={onServiceDeviceChangeSort}
        onPageNumberChange={onPageNumberChange}
      /> */}

      {/* Device Reference is commented out because it is not used in EP1 - requested by Josh */}
      {/* <DeviceReference data={deviceReferenceList.data} loading={deviceReferenceList.loading} /> */}
      <FormAction
        onCancel={onCancel}
        onSubmit={() => {
          form.submit();
          billingForm.submit();
        }}
        SubmitButtonPermission={{
          roleKey: ERoleKey.UPDATE
        }}
      />
    </Form>
  );
}

export default AccountDetailPageView;
