import { useCallback, useState } from 'react';
import type { TPaginationEOSS } from '../models';

export type AsyncState<T> = {
  data: T;
  loading: boolean;
  paging?: TPaginationEOSS;
};

/**
 * A custom React hook to manage asynchronous state with optional pagination and loading indicators.
 *
 * @template T - The type of the data being managed by the hook.
 *
 * @param {T | (() => T)} initialValue - The initial value for the `data` field. Can be a value or a lazy initializer function.
 * @param {TPaginationEOSS} [paginationConfig] - Optional pagination configuration to initialize the `paging` field.
 *
 * @returns {[AsyncState<T>, React.Dispatch<React.SetStateAction<AsyncState<T>>>, () => void, () => void]}
 * - A tuple containing:
 *   1. `state`: The current state object, including `data`, `loading`, and `paging`.
 *   2. `setState`: Function to update the state.
 *   3. `startLoading`: Function to set the `loading` field to `true`.
 *   4. `stopLoading`: Function to set the `loading` field to `false`.
 *
 * @example
 * // Example usage
 * const [state, setState, startLoading, stopLoading] = useAsyncState<Array<{ id: number; name: string }>>(
 *   () => fetchInitialData(),
 *   { page: 1, pageSize: 10 }
 * );
 *
 * startLoading();
 * fetchSomeData().then(data => {
 *   setState(prev => ({ ...prev, data }));
 *   stopLoading();
 * });
 */
export function useAsyncState<T extends AnyObject>(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  initialValue: T | (() => T), // Lazy initialzation
  paginationConfig?: TPaginationEOSS
) {
  const [state, setState] = useState<AsyncState<T>>({
    loading: false,
    data: typeof initialValue === 'function' ? (initialValue as () => T)() : initialValue,
    paging: paginationConfig
  });

  const startLoading = useCallback(() => {
    setState((prev) => ({
      ...prev,
      loading: true
    }));
  }, []);

  const stopLoading = useCallback(() => {
    setState((prev) => ({
      ...prev,
      loading: false
    }));
  }, []);

  return [state, setState, startLoading, stopLoading] as const;
}
