import { Form, FormInstance, Input, Select, Space, Tooltip, TreeSelect } from 'antd';
import { DropdownIcon, MoreInfoIcon } from 'assets/icons';
import { EDeviceType, ESubFeatureKey } from 'enums';
import type { DataTypeService, TAccount, TServer } from 'models';
import { FormInputTooltip, PermissionWrapper } from 'presentation/components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { FormInformationFieldType } from '../DeviceDetailPageController';
import TextArea from 'antd/es/input/TextArea';
import styles from './formDeviceInformation.module.scss';
import { orderBy } from 'lodash';
import {
  IP_CAMERA_SERVICE_PACKAGE_NAME,
  MAXIMUM_128_CHARACTERS,
  MAXIMUM_32_CHARACTERS,
  OVERLAY_CAMERA_SERVICE_PACKAGE_NAME,
  THERMAL_ANALYTIC_SERVICE_PACKAGE_NAME,
  THERMAL_CAMERA_SERVICE_PACKAGE_NAME
} from 'constant';
import ValidationUtilities from 'utils/validationUtils';
import { checkFormIsValid, findServiceNameById, scrollToFirstFieldError } from 'utils/common';
import cameraBlinking from 'assets/images/camera_light_blinking.gif';
import cameraSolid from 'assets/images/camera_light_solid.gif';

type Props = {
  form: FormInstance<FormInformationFieldType>;
  deviceType: string;
  listAccounts: TAccount[];
  listServicesPackage: DataTypeService[];
  listServerBVR: TServer[];
  isIPCamera: boolean;
  isThermalCamera: boolean;
  isThermalOpticalCamera: boolean;
  haveOverlayCamera: boolean;
  isAstro: boolean;
  isUPSMonitor: boolean;
  isOpticalRecording: boolean;
  isThermalRecording: boolean;
  isOpticalOnline: boolean;
  isThermalOnline: boolean;
  isDomainCustomerAdmin: boolean;
  hasDeviceUpdateServicePermisison: boolean;
  onSubmit: () => void;
};

const FormDeviceInformation: FC<Props> = ({
  form,
  deviceType,
  listAccounts,
  listServicesPackage,
  listServerBVR,
  isIPCamera,
  isThermalCamera,
  isThermalOpticalCamera,
  haveOverlayCamera,
  isOpticalRecording,
  isThermalRecording,
  isOpticalOnline,
  isThermalOnline,
  isAstro,
  isUPSMonitor,
  isDomainCustomerAdmin,
  hasDeviceUpdateServicePermisison,
  onSubmit
}) => {
  const { t } = useTranslation();

  const currentBVRPrimary = Form.useWatch<number>('bvrServerPrimary', form);
  const currentBVRSecondary = Form.useWatch<number>('bvrServerSecondary', form);
  const currentBVRTertiary = Form.useWatch<number>('bvrServerTertiary', form);

  const filterOption = (input: string, option?: { label: string; value: string | number }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const mapToTreeSelect = (
    service: DataTypeService
  ): {
    title: string;
    value: number;
    children: { title: string; value: number }[];
    selectable?: boolean;
    disabled?: boolean;
    className?: string;
    style?: React.CSSProperties;
  } => {
    if (!service.children) {
      return {
        title: service.name,
        value: service.id,
        children: [],
        selectable: true
      };
    }

    return {
      title: service.name,
      value: service.id,
      children: service.children?.map((s) => mapToTreeSelect(s)),
      selectable: false
    };
  };

  return (
    <div className="d-flex w-100">
      <Form
        id="devicePage_deviceInformationForm"
        className={styles.container}
        labelCol={{
          style: {
            width: 210
          }
        }}
        form={form}
        layout="horizontal"
        scrollToFirstError={{ behavior: 'smooth', block: 'center', scrollMode: 'if-needed' }}
        style={{ width: '100%' }}
        labelAlign="right"
        onFinish={() => {
          if (!checkFormIsValid(form)) {
            scrollToFirstFieldError(form);
            return;
          }
          onSubmit();
        }}
      >
        {/*MODEL*/}
        <FormInputTooltip name="modelData">
          <Form.Item<FormInformationFieldType>
            name="modelData"
            label={
              <div className="d-flex align-items-center">
                <span className="text-truncate">{t('devicePage.sections.deviceInfo.model')}</span>
                <Tooltip
                  title={t(
                    `devicePage.sections.deviceInfo.tooltip.${isAstro ? 'modelAstro' : 'model'}`
                  )}
                  trigger="click"
                >
                  <div className="d-flex align-items-center cursor-pointer">
                    <MoreInfoIcon />
                  </div>
                </Tooltip>
              </div>
            }
            colon={false}
          >
            {deviceType === EDeviceType.THERMAL_OPTICAL_CAMERA ? (
              <TextArea
                id="model-text-area-form-device-information-device-page"
                className={`${styles.textArea} text-truncate`}
                disabled
                rows={2}
              />
            ) : (
              <Input
                id="model-input-form-device-information-device-page"
                className={`${styles.formInput} text-truncate`}
                disabled
              />
            )}
          </Form.Item>
        </FormInputTooltip>
        {/*SERIAL NUMBER*/}
        <FormInputTooltip name="serialNumber">
          <Form.Item<FormInformationFieldType>
            name="serialNumber"
            label={
              <span className="text-truncate">
                {t('devicePage.sections.deviceInfo.serialNumber')}
              </span>
            }
            colon={false}
          >
            <Input className={`${styles.formInput} text-truncate`} disabled />
          </Form.Item>
        </FormInputTooltip>
        {isAstro && (
          <>
            {/**
             * ERRP-212: Hide this field, Request by Josh 2024-Sep-06
             */}
            {/*AI MODEL NAME VERSION*/}
            {/* <FormInputTooltip name="AIModelNameVersion">
              <Form.Item<FormInformationFieldType>
                name="AIModelNameVersion"
                label={
                  <span className="text-truncate">
                    {t('devicePage.sections.deviceInfo.AIModelNameVersion')}
                  </span>
                }
                colon={false}
              >
                <Input className={`${styles.formInput} text-truncate`} disabled />
              </Form.Item>
            </FormInputTooltip> */}
            {/*ATTACHED CAMERA*/}
            <FormInputTooltip name="attachedCamera">
              <Form.Item<FormInformationFieldType>
                name="attachedCamera"
                label={
                  <span className="text-truncate">
                    {t('devicePage.sections.deviceInfo.attachedCamera')}
                  </span>
                }
                colon={false}
              >
                <Input className={`${styles.formInput} text-truncate`} disabled />
              </Form.Item>
            </FormInputTooltip>
          </>
        )}
        {/*IP ADDRESS*/}
        <FormInputTooltip name="localIPAddress">
          <Form.Item<FormInformationFieldType>
            name="localIPAddress"
            label={
              <div className="d-flex align-items-center">
                <span className="text-truncate">
                  {t('devicePage.sections.deviceInfo.ipAddress')}
                </span>
                <Tooltip
                  title={t('devicePage.sections.deviceInfo.tooltip.ipAddress')}
                  placement="top"
                  trigger="click"
                >
                  <div className="d-flex align-items-center cursor-pointer">
                    <MoreInfoIcon />
                  </div>
                </Tooltip>
              </div>
            }
            colon={false}
          >
            <Input
              id="ip-address-input-form-device-information-device-page"
              className={`${styles.formInput} text-truncate`}
              disabled
            />
          </Form.Item>
        </FormInputTooltip>
        {/*SOC (RPi) BOARD AND POE*/}
        {!isAstro && !isUPSMonitor && (
          <Form.Item
            label={
              <div className="d-flex align-items-center">
                <span className="text-truncate">{t('devicePage.sections.deviceInfo.SOC&POE')}</span>
                <Tooltip
                  title={
                    <span>{`${t('devicePage.sections.deviceInfo.tooltip.SOC')} | ${t(
                      'devicePage.sections.deviceInfo.tooltip.POE'
                    )}`}</span>
                  }
                  trigger="click"
                  placement="top"
                >
                  <div className="d-flex align-items-center cursor-pointer">
                    <MoreInfoIcon />
                  </div>
                </Tooltip>
              </div>
            }
            className="mb-0"
            colon={false}
          >
            <div className="d-flex w-100 gap-3">
              <FormInputTooltip name="soc" className="w-100">
                <Form.Item<FormInformationFieldType> name="soc" className="mb-0">
                  <Input className="text-truncate" disabled />
                </Form.Item>
              </FormInputTooltip>
              <FormInputTooltip name="poe" className="w-100 mb-0">
                <Form.Item<FormInformationFieldType> name="poe">
                  <Input className="text-truncate" disabled />
                </Form.Item>
              </FormInputTooltip>
            </div>
          </Form.Item>
        )}
        {/*MAC ADDRESS*/}
        <FormInputTooltip name="mac">
          <Form.Item<FormInformationFieldType>
            name="mac"
            label={<span className="text-truncate">{t('devicePage.sections.deviceInfo.mac')}</span>}
            colon={false}
          >
            <Input className={`${styles.formInput} text-truncate`} disabled />
          </Form.Item>
        </FormInputTooltip>
        {/*CUSTOMER*/}
        {isUPSMonitor && (
          <>
            {!!form.getFieldValue('lineVolt') && (
              <FormInputTooltip name="lineVolt">
                <Form.Item<FormInformationFieldType>
                  name="lineVolt"
                  label={
                    <span className="text-truncate">
                      {t('devicePage.sections.deviceInfo.lineVoltage')}
                    </span>
                  }
                  colon={false}
                >
                  <Input className="text-truncate" disabled />
                </Form.Item>
              </FormInputTooltip>
            )}
            {!!form.getFieldValue('batteryVolt') && (
              <FormInputTooltip name="batteryVolt">
                <Form.Item<FormInformationFieldType>
                  name="batteryVolt"
                  label={
                    <span className="text-truncate">
                      {t('devicePage.sections.deviceInfo.batteryVoltage')}
                    </span>
                  }
                  colon={false}
                >
                  <Input className="text-truncate" disabled />
                </Form.Item>
              </FormInputTooltip>
            )}
            {!!form.getFieldValue('load') && (
              <FormInputTooltip name="load">
                <Form.Item<FormInformationFieldType>
                  name="load"
                  label={
                    <span className="text-truncate">
                      {t('devicePage.sections.deviceInfo.load')}
                    </span>
                  }
                  colon={false}
                >
                  <Input className="text-truncate" disabled />
                </Form.Item>
              </FormInputTooltip>
            )}
            {!!form.getFieldValue('batteryCharge') && (
              <FormInputTooltip name="batteryCharge">
                <Form.Item<FormInformationFieldType>
                  name="batteryCharge"
                  label={
                    <span className="text-truncate">
                      {t('devicePage.sections.deviceInfo.batteryCharge')}
                    </span>
                  }
                  colon={false}
                >
                  <Input className="text-truncate" disabled />
                </Form.Item>
              </FormInputTooltip>
            )}
            {!!form.getFieldValue('batteryDate') && (
              <FormInputTooltip name="batteryDate">
                <Form.Item<FormInformationFieldType>
                  name="batteryDate"
                  label={
                    <span className="text-truncate">
                      {t('devicePage.sections.deviceInfo.batteryDate')}
                    </span>
                  }
                  colon={false}
                >
                  <Input className="text-truncate" disabled />
                </Form.Item>
              </FormInputTooltip>
            )}
          </>
        )}
        <PermissionWrapper.Update
          subFeature={ESubFeatureKey.SERVICE}
          config={{ displayType: 'disabled' }}
        >
          <Form.Item<FormInformationFieldType>
            name="account"
            label={
              <span className="text-truncate">{t('devicePage.sections.deviceInfo.customer')}</span>
            }
            colon={false}
          >
            {!hasDeviceUpdateServicePermisison ? (
              <Input disabled />
            ) : (
              <Select
                className={styles.formInput}
                showSearch
                optionFilterProp="children"
                filterOption={filterOption}
                options={orderBy(listAccounts, 'name', ['asc']).map((account) => {
                  return {
                    value: account.id,
                    label: account.name
                  };
                })}
                labelInValue
                suffixIcon={<DropdownIcon />}
              />
            )}
          </Form.Item>
        </PermissionWrapper.Update>
        {/*SERVICE*/}
        {(isIPCamera || isThermalOpticalCamera) && (
          <PermissionWrapper.Update
            subFeature={ESubFeatureKey.SERVICE}
            config={{ displayType: 'disabled' }}
          >
            {!hasDeviceUpdateServicePermisison ? (
              <Form.Item
                label={
                  <span className="text-truncate">
                    {t(
                      `devicePage.sections.deviceInfo.service.${
                        isIPCamera ? 'ipService' : 'opticalService'
                      }`
                    )}
                  </span>
                }
                colon={false}
              >
                <Input
                  disabled
                  value={
                    Number(form.getFieldValue('opticalService')) === -1
                      ? '-'
                      : findServiceNameById(
                          listServicesPackage,
                          Number(form.getFieldValue('opticalService'))
                        )
                  }
                />
              </Form.Item>
            ) : (
              <Form.Item<FormInformationFieldType>
                name="opticalService"
                label={
                  <span className="text-truncate">
                    {t(
                      `devicePage.sections.deviceInfo.service.${
                        isIPCamera ? 'ipService' : 'opticalService'
                      }`
                    )}
                  </span>
                }
                colon={false}
              >
                <TreeSelect
                  className={styles.formInput}
                  showSearch
                  treeDefaultExpandAll
                  suffixIcon={<DropdownIcon />}
                  treeData={[
                    { key: -1, label: '-', value: -1 },
                    ...listServicesPackage
                      .filter((service) => service.name.includes(IP_CAMERA_SERVICE_PACKAGE_NAME))
                      .map((servicePackage) => mapToTreeSelect(servicePackage))
                  ]}
                  filterTreeNode={(input, treeNode) => {
                    return Boolean(
                      treeNode.title?.toString().toLowerCase().includes(input.toLowerCase())
                    );
                  }}
                />
              </Form.Item>
            )}
          </PermissionWrapper.Update>
        )}
        {/*THERMAL SERVICE*/}
        {(isThermalCamera || isThermalOpticalCamera) && (
          <PermissionWrapper.Update
            subFeature={ESubFeatureKey.SERVICE}
            config={{ displayType: 'disabled' }}
          >
            {!hasDeviceUpdateServicePermisison ? (
              <Form.Item
                label={
                  <span className="text-truncate">
                    {t('devicePage.sections.deviceInfo.service.thermalService')}
                  </span>
                }
                colon={false}
              >
                <Input
                  disabled
                  value={
                    Number(form.getFieldValue('thermalService')) === -1
                      ? '-'
                      : findServiceNameById(
                          listServicesPackage,
                          Number(form.getFieldValue('thermalService'))
                        )
                  }
                />
              </Form.Item>
            ) : (
              <Form.Item<FormInformationFieldType>
                name="thermalService"
                label={
                  <span className="text-truncate">
                    {t('devicePage.sections.deviceInfo.service.thermalService')}
                  </span>
                }
                colon={false}
              >
                {!hasDeviceUpdateServicePermisison ? (
                  <Input disabled />
                ) : (
                  <TreeSelect
                    className={styles.formInput}
                    showSearch
                    treeDefaultExpandAll
                    suffixIcon={<DropdownIcon />}
                    treeData={[
                      { key: -1, label: '-', value: -1 },
                      ...listServicesPackage
                        .filter((service) =>
                          service.name.includes(THERMAL_CAMERA_SERVICE_PACKAGE_NAME)
                        )
                        .map((servicePackage) => mapToTreeSelect(servicePackage))
                    ]}
                    filterTreeNode={(input, treeNode) => {
                      return Boolean(
                        treeNode.title?.toString().toLowerCase().includes(input.toLowerCase())
                      );
                    }}
                  />
                )}
              </Form.Item>
            )}
          </PermissionWrapper.Update>
        )}
        {/*THERMAL ANALYTIC SERVICE*/}
        {(isThermalCamera || isThermalOpticalCamera) && (
          <PermissionWrapper.Update
            subFeature={ESubFeatureKey.SERVICE}
            config={{ displayType: 'disabled' }}
          >
            {!hasDeviceUpdateServicePermisison ? (
              <Form.Item
                label={
                  <span className="text-truncate">
                    {t('devicePage.sections.deviceInfo.service.thermalAnalyticService')}
                  </span>
                }
                colon={false}
              >
                <Input
                  disabled
                  value={
                    Number(form.getFieldValue('thermalAnalyticService')) === -1
                      ? '-'
                      : findServiceNameById(
                          listServicesPackage,
                          Number(form.getFieldValue('thermalAnalyticService'))
                        )
                  }
                />
              </Form.Item>
            ) : (
              <Form.Item<FormInformationFieldType>
                name="thermalAnalyticService"
                label={
                  <span className="text-truncate">
                    {t('devicePage.sections.deviceInfo.service.thermalAnalyticService')}
                  </span>
                }
                colon={false}
              >
                <TreeSelect
                  showSearch
                  treeDefaultExpandAll
                  suffixIcon={<DropdownIcon />}
                  treeData={[
                    { key: -1, label: '-', value: -1 },
                    ...listServicesPackage
                      .filter((service) =>
                        service.name.includes(THERMAL_ANALYTIC_SERVICE_PACKAGE_NAME)
                      )
                      .map((servicePackage) => mapToTreeSelect(servicePackage))
                  ]}
                  filterTreeNode={(input, treeNode) => {
                    return Boolean(
                      treeNode.title?.toString().toLowerCase().includes(input.toLowerCase())
                    );
                  }}
                />
              </Form.Item>
            )}
          </PermissionWrapper.Update>
        )}
        {/*OVERLAY CAMERA SERVICE */}
        {isThermalOpticalCamera && haveOverlayCamera && (
          <PermissionWrapper.Update
            subFeature={ESubFeatureKey.SERVICE}
            config={{ displayType: 'disabled' }}
          >
            {!hasDeviceUpdateServicePermisison ? (
              <Form.Item
                label={
                  <span className="text-truncate">
                    {t('devicePage.sections.deviceInfo.service.overlayCameraService')}
                  </span>
                }
                colon={false}
              >
                <Input
                  disabled
                  value={
                    Number(form.getFieldValue('overlayCameraService')) === -1
                      ? '-'
                      : findServiceNameById(
                          listServicesPackage,
                          Number(form.getFieldValue('overlayCameraService'))
                        )
                  }
                />
              </Form.Item>
            ) : (
              <Form.Item<FormInformationFieldType>
                name="overlayCameraService"
                label={
                  <span className="text-truncate">
                    {t('devicePage.sections.deviceInfo.service.overlayCameraService')}
                  </span>
                }
                colon={false}
              >
                <TreeSelect
                  showSearch
                  treeDefaultExpandAll
                  suffixIcon={<DropdownIcon />}
                  treeData={[
                    { key: -1, label: '-', value: -1 },
                    ...listServicesPackage
                      .filter((service) =>
                        service.name.includes(OVERLAY_CAMERA_SERVICE_PACKAGE_NAME)
                      )
                      .map((servicePackage) => mapToTreeSelect(servicePackage))
                  ]}
                  filterTreeNode={(input, treeNode) => {
                    return Boolean(
                      treeNode.title?.toString().toLowerCase().includes(input.toLowerCase())
                    );
                  }}
                />
              </Form.Item>
            )}
          </PermissionWrapper.Update>
        )}
        {/*PRIMARY BVR SERVER*/}
        {(isIPCamera || isThermalCamera || isThermalOpticalCamera) && !isDomainCustomerAdmin && (
          <PermissionWrapper.Update
            subFeature={ESubFeatureKey.SERVICE}
            config={{ displayType: 'disabled' }}
          >
            <Form.Item
              label={
                <span className="text-truncate">
                  {t('devicePage.sections.deviceInfo.brvServer')}
                </span>
              }
              colon={false}
            >
              <Space.Compact block className={styles.select} rootClassName="mb-2">
                {!hasDeviceUpdateServicePermisison ? (
                  <Form.Item>
                    <Input
                      value={
                        currentBVRPrimary === -1
                          ? '-'
                          : listServerBVR.find(
                              ({ id }: TServer) =>
                                id === Number(form.getFieldValue('bvrServerPrimary'))
                            )?.name ?? '-'
                      }
                      disabled
                    />
                  </Form.Item>
                ) : (
                  <Form.Item<FormInformationFieldType> name="bvrServerPrimary">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={filterOption}
                      options={[
                        { label: '-', value: -1 },
                        ...listServerBVR
                          .filter(
                            (server) =>
                              server.id !== currentBVRSecondary && server.id !== currentBVRTertiary
                          )
                          .map((server) => ({
                            value: server.id,
                            label: server.name
                          }))
                      ]}
                      onChange={(value) => {
                        if (value === -1) {
                          form.setFieldValue('bvrServerSecondary', -1);
                          form.setFieldValue('bvrServerTertiary', -1);
                        }
                      }}
                      suffixIcon={<DropdownIcon />}
                    />
                  </Form.Item>
                )}
                <Form.Item>
                  <span
                    style={{
                      marginLeft: 10,
                      fontStyle: 'italic',
                      textAlign: 'end',
                      maxWidth: 80
                    }}
                  >
                    (primary)
                  </span>
                </Form.Item>
              </Space.Compact>
              <Space.Compact block className={styles.select} rootClassName="mb-2">
                {!hasDeviceUpdateServicePermisison ||
                Number(form.getFieldValue('bvrServerPrimary')) === -1 ? (
                  <Form.Item>
                    <Input
                      value={
                        currentBVRSecondary === -1
                          ? '-'
                          : listServerBVR.find(
                              ({ id }: TServer) =>
                                id === Number(form.getFieldValue('bvrServerSecondary'))
                            )?.name ?? '-'
                      }
                      disabled
                    />
                  </Form.Item>
                ) : (
                  <Form.Item<FormInformationFieldType> name="bvrServerSecondary">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={filterOption}
                      disabled={!currentBVRPrimary || currentBVRPrimary === -1}
                      options={[
                        { label: '-', value: -1 },
                        ...listServerBVR
                          .filter(
                            (server) =>
                              server.id !== currentBVRPrimary && server.id !== currentBVRTertiary
                          )
                          .map((server) => ({
                            value: server.id,
                            label: server.name
                          }))
                      ]}
                      onChange={(value) => {
                        if (value === -1) {
                          form.setFieldValue('bvrServerTertiary', -1);
                        }
                      }}
                      suffixIcon={<DropdownIcon />}
                    />
                  </Form.Item>
                )}
                <Form.Item>
                  <span
                    style={{
                      marginLeft: 10,
                      fontStyle: 'italic',
                      textAlign: 'end',
                      maxWidth: 80
                    }}
                  >
                    (secondary)
                  </span>
                </Form.Item>
              </Space.Compact>
              <Space.Compact block className={styles.select}>
                {!hasDeviceUpdateServicePermisison ||
                Number(form.getFieldValue('bvrServerSecondary')) === -1 ? (
                  <Form.Item>
                    <Input
                      value={
                        currentBVRTertiary === -1
                          ? '-'
                          : listServerBVR.find(
                              ({ id }: TServer) =>
                                id === Number(form.getFieldValue('bvrServerTertiary'))
                            )?.name ?? '-'
                      }
                      disabled
                    />
                  </Form.Item>
                ) : (
                  <Form.Item<FormInformationFieldType> name="bvrServerTertiary" className="mb-0">
                    <Select
                      className={styles.formInput}
                      showSearch
                      optionFilterProp="children"
                      filterOption={filterOption}
                      disabled={!currentBVRSecondary || currentBVRSecondary === -1}
                      options={[
                        { label: '-', value: -1 },
                        ...listServerBVR
                          .filter(
                            (server) =>
                              server.id !== currentBVRPrimary && server.id !== currentBVRSecondary
                          )
                          .map((server) => ({
                            value: server.id,
                            label: server.name
                          }))
                      ]}
                      suffixIcon={<DropdownIcon />}
                    />
                  </Form.Item>
                )}
                <Form.Item>
                  <span
                    style={{
                      marginLeft: 10,
                      fontStyle: 'italic',
                      textAlign: 'end',
                      maxWidth: 80
                    }}
                  >
                    (tertiary)
                  </span>
                </Form.Item>
              </Space.Compact>
            </Form.Item>
          </PermissionWrapper.Update>
        )}
        {/*USAGE START AT*/}
        <FormInputTooltip name="usageStartAt">
          <Form.Item<FormInformationFieldType>
            name="usageStartAt"
            label={
              <span className="text-truncate">
                {t('devicePage.sections.deviceInfo.usageStartDateAndTime')}
              </span>
            }
            colon={false}
          >
            <Input className="text-truncate" disabled />
          </Form.Item>
        </FormInputTooltip>
        {/*CAMERA NAME*/}
        {(isIPCamera || isThermalCamera || isThermalOpticalCamera) && (
          <PermissionWrapper.Update
            subFeature={ESubFeatureKey.SERVICE}
            config={{ displayType: 'disabled' }}
          >
            <Form.Item
              label={
                <span className="text-truncate">
                  {t('devicePage.sections.deviceInfo.cameraName')}
                </span>
              }
              className="w-100"
              colon={false}
            >
              {(isIPCamera || isThermalCamera) && (
                <div className="d-flex align-items-center">
                  <FormInputTooltip
                    className="flex-fill"
                    name={isIPCamera ? 'opticalName' : 'thermalName'}
                  >
                    <Form.Item<FormInformationFieldType>
                      name={isIPCamera ? 'opticalName' : 'thermalName'}
                      rules={[
                        {
                          validator: (_, value: string) =>
                            ValidationUtilities.deviceNameValidationFn({
                              value,
                              errorMessage: t('forms.name.customMaxLength', {
                                length: MAXIMUM_128_CHARACTERS
                              })
                            })
                        }
                      ]}
                      className="mb-0"
                    >
                      <Input className="text-truncate" />
                    </Form.Item>
                  </FormInputTooltip>
                  {((isIPCamera && isOpticalRecording) ||
                    (isThermalCamera && isThermalRecording)) && (
                    <div className="px-1">
                      <Tooltip trigger="hover" title={t('devicePage.cameraRecording')}>
                        <img src={cameraBlinking} alt="" />
                      </Tooltip>
                    </div>
                  )}
                  {((isIPCamera && isOpticalOnline && !isOpticalRecording) ||
                    (isThermalCamera && isThermalOnline && !isThermalRecording)) && (
                    <div className="px-1">
                      <Tooltip trigger="hover" title={t('devicePage.cameraOnline')}>
                        <img src={cameraSolid} alt="" />
                      </Tooltip>
                    </div>
                  )}
                </div>
              )}
              {isThermalOpticalCamera && (
                <div className="row gx-3 gy-2 gy-slg-0">
                  <div className="col-12 col-slg-6 d-flex align-items-center">
                    <FormInputTooltip name="opticalName" className="flex-fill">
                      <Form.Item<FormInformationFieldType>
                        name="opticalName"
                        className="mb-0"
                        rules={[
                          {
                            validator: (_, value: string) =>
                              ValidationUtilities.deviceNameValidationFn({
                                value,
                                errorMessage: t('forms.name.customMaxLength', {
                                  length: MAXIMUM_128_CHARACTERS
                                })
                              })
                          }
                        ]}
                      >
                        <Input
                          className={`${styles.addonData} text-truncate`}
                          addonBefore="Optical"
                        />
                      </Form.Item>
                    </FormInputTooltip>
                    {isOpticalRecording && (
                      <div className="px-1">
                        <Tooltip trigger="hover" title={t('devicePage.cameraRecording')}>
                          <img src={cameraBlinking} alt="" />
                        </Tooltip>
                      </div>
                    )}
                    {isOpticalOnline && !isOpticalRecording && (
                      <div className="px-1">
                        <Tooltip trigger="hover" title={t('devicePage.cameraOnline')}>
                          <img src={cameraSolid} alt="" />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-slg-6 d-flex align-items-center">
                    <FormInputTooltip name="thermalName" className="flex-fill">
                      <Form.Item<FormInformationFieldType>
                        name="thermalName"
                        className="mb-0"
                        rules={[
                          {
                            validator: (_, value: string) =>
                              ValidationUtilities.deviceNameValidationFn({
                                value,
                                errorMessage: t('forms.name.customMaxLength', {
                                  length: MAXIMUM_128_CHARACTERS
                                })
                              })
                          }
                        ]}
                      >
                        <Input
                          className={`${styles.addonData} text-truncate`}
                          addonBefore="Thermal"
                        />
                      </Form.Item>
                    </FormInputTooltip>
                    {isThermalRecording && (
                      <div className="px-1">
                        <Tooltip trigger="hover" title={t('devicePage.cameraRecording')}>
                          <img src={cameraBlinking} alt="" />
                        </Tooltip>
                      </div>
                    )}
                    {isThermalOnline && !isThermalRecording && (
                      <div className="px-1">
                        <Tooltip trigger="hover" title={t('devicePage.cameraOnline')}>
                          <img src={cameraSolid} alt="" />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Form.Item>
          </PermissionWrapper.Update>
        )}
        {/*DEVICE MOUNT LOCATION*/}
        <PermissionWrapper.Update
          subFeature={ESubFeatureKey.BASIC}
          config={{ displayType: 'disabled' }}
        >
          <FormInputTooltip name="mountLocation">
            <Form.Item<FormInformationFieldType>
              name="mountLocation"
              label={<span>{t('forms.mountLocation.label')}</span>}
              colon={false}
              rules={[
                {
                  validator: (_, value: string) =>
                    ValidationUtilities.mountLocationValidationFn({
                      value,
                      errorMessage: t('forms.mountLocation.maxLength')
                    })
                }
              ]}
            >
              <Input className="text-truncate" />
            </Form.Item>
          </FormInputTooltip>
        </PermissionWrapper.Update>
        {/*CAMERA FORMS AND LENS*/}
        {isIPCamera && (
          <Form.Item
            label={<span className="text-truncate">{t('forms.formsAndLens.label')}</span>}
            className="mb-0"
            colon={false}
          >
            <div className="d-flex gap-3 w-100">
              <PermissionWrapper.Update
                subFeature={ESubFeatureKey.BASIC}
                config={{ displayType: 'disabled' }}
              >
                <FormInputTooltip name="formFactor" className="w-100">
                  <Form.Item<FormInformationFieldType>
                    name="formFactor"
                    rules={[
                      {
                        validator: (_, value: string) =>
                          ValidationUtilities.formFactorAndLensValidationFn({
                            value,
                            errorMessage: t('forms.lens.customMaxLength', {
                              length: MAXIMUM_32_CHARACTERS
                            })
                          })
                      }
                    ]}
                  >
                    <Input className={`${styles.addonData} text-truncate`} addonBefore="Forms" />
                  </Form.Item>
                </FormInputTooltip>
              </PermissionWrapper.Update>
              <PermissionWrapper.Update
                subFeature={ESubFeatureKey.SERVICE}
                config={{ displayType: 'disabled' }}
              >
                <FormInputTooltip name="opticalLens" className="w-100">
                  <Form.Item<FormInformationFieldType>
                    name="opticalLens"
                    rules={[
                      {
                        validator: (_, value: string) =>
                          ValidationUtilities.formFactorAndLensValidationFn({
                            value,
                            errorMessage: t('forms.lens.customMaxLength', {
                              length: MAXIMUM_32_CHARACTERS
                            })
                          })
                      }
                    ]}
                  >
                    <Input className={`${styles.addonData} text-truncate`} addonBefore="Lens" />
                  </Form.Item>
                </FormInputTooltip>
              </PermissionWrapper.Update>
            </div>
          </Form.Item>
        )}
        {/*CAMERA LENS*/}
        {(isThermalCamera || isThermalOpticalCamera) && (
          <PermissionWrapper.Update
            subFeature={ESubFeatureKey.SERVICE}
            config={{ displayType: 'disabled' }}
          >
            <Form.Item
              label={
                <span className="text-truncate">
                  {t('devicePage.sections.deviceInfo.cameraLens')}
                </span>
              }
              colon={false}
              className="mb-2 w-100"
            >
              {isThermalCamera && (
                <FormInputTooltip name={isIPCamera ? 'opticalLens' : 'thermalLens'}>
                  <Form.Item<FormInformationFieldType>
                    name={isIPCamera ? 'opticalLens' : 'thermalLens'}
                    rules={[
                      {
                        validator: (_, value: string) =>
                          ValidationUtilities.formFactorAndLensValidationFn({
                            value,
                            errorMessage: t('forms.lens.customMaxLength', {
                              length: MAXIMUM_32_CHARACTERS
                            })
                          })
                      }
                    ]}
                    className="mb-0"
                  >
                    <Input className="text-truncate" />
                  </Form.Item>
                </FormInputTooltip>
              )}
              {isThermalOpticalCamera && (
                <div className="d-flex gap-3 w-100">
                  <FormInputTooltip name="opticalLens" className="w-100">
                    <Form.Item<FormInformationFieldType>
                      name="opticalLens"
                      rules={[
                        {
                          validator: (_, value: string) =>
                            ValidationUtilities.formFactorAndLensValidationFn({
                              value,
                              errorMessage: t('forms.lens.customMaxLength', {
                                length: MAXIMUM_32_CHARACTERS
                              })
                            })
                        }
                      ]}
                      className="mb-0"
                    >
                      <Input
                        className={`${styles.addonData} text-truncate`}
                        addonBefore="Optical"
                      />
                    </Form.Item>
                  </FormInputTooltip>
                  <FormInputTooltip name="thermalLens" className="w-100">
                    <Form.Item<FormInformationFieldType>
                      name="thermalLens"
                      rules={[
                        {
                          validator: (_, value: string) =>
                            ValidationUtilities.formFactorAndLensValidationFn({
                              value,
                              errorMessage: t('forms.lens.customMaxLength', {
                                length: MAXIMUM_32_CHARACTERS
                              })
                            })
                        }
                      ]}
                      className="mb-0"
                    >
                      <Input
                        className={`${styles.addonData} text-truncate`}
                        addonBefore="Thermal"
                      />
                    </Form.Item>
                  </FormInputTooltip>
                </div>
              )}
            </Form.Item>
          </PermissionWrapper.Update>
        )}
        {/*DEVICE LOCATION*/}
        <PermissionWrapper.Update
          subFeature={ESubFeatureKey.BASIC}
          config={{ displayType: 'disabled' }}
        >
          <FormInputTooltip name="location">
            <Form.Item<FormInformationFieldType>
              name="location"
              label={<span>{t('forms.deviceLocation.label')}</span>}
              colon={false}
              rules={[
                {
                  validator: (_, value: string) =>
                    ValidationUtilities.locationValidationFn({
                      value,
                      errorMessage: t('forms.deviceLocation.maxLength')
                    })
                }
              ]}
            >
              <Input className="text-truncate" />
            </Form.Item>
          </FormInputTooltip>
        </PermissionWrapper.Update>

        {/*COMMENT*/}
        <PermissionWrapper.Update
          subFeature={ESubFeatureKey.BASIC}
          config={{ displayType: 'disabled' }}
        >
          <Form.Item<FormInformationFieldType>
            name="comment"
            label={<span className="text-truncate">{t('forms.deviceComment.label')}</span>}
            colon={false}
            rules={[
              {
                validator: (_, value: string) =>
                  ValidationUtilities.commentValidationFn({
                    value,
                    errorMessage: t('forms.comment.maxLength')
                  })
              }
            ]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
        </PermissionWrapper.Update>
      </Form>
    </div>
  );
};

export default FormDeviceInformation;
